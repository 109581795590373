<style lang="less" scoped>
// 滤镜Css
.filter-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .fliter_selects {
        flex: 1;
        height: 0;
        background-color: #f0f8ff;
        padding: 10px 0;
    }
    // background-color: #f0f8ff;
}
.fliter_select_group {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    height: 100%;
    padding: 0 10px;
    overflow: hidden auto;
    .list {
        border: 2px solid transparent;
        border-radius: 4px;
        &.active {
            border-color: #349ffd;
        }
        background-color: #fff;
        .img_box {
            height: 70px;
            width: 70px;
            margin: 0 auto;
        }
        .fliter_label {
            line-height: 1.5;
            text-align: center;
            font-size: 12px;
            background-color: #c1cbd8;
            color: #fff;
        }
    }
}
.fliter_set {
    background-color: #f0f8ff;
    height: 200px;
    margin-top: 10px;
    .fliter_adjust {
        display: flex;
        align-items: center;
        padding: 0 10px;
        .adjust {
            flex: 1;
        }
        .fliter_set_label {
            width: 80px;
        }
        .fliter_set_num {
            width: 50px;
            padding: 0 8px;
            font-size: 12px;
            text-align: center;
        }
    }
}
// 贴纸
.sticker-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .sticker-group {
        flex: 1;
        height: 0;
        overflow: hidden auto;
    }
    .sticker-selects {
        height: 100px;
        background: #f3f3f3;
        margin: 0 -15px;
        padding: 10px;
        box-sizing: border-box;
    }
}
.sticker-group {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px 100px 100px 100px;
    padding: 10px 0;

    .sticker-list {
        position: relative;
        background-color: #f1f1f1;
        line-height: 1;
        &.active {
            border: 1px solid #349ffd;
        }
        .iconfont {
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 9;
            color: #dd2020;
            cursor: pointer;
        }
    }
}
.select-group {
    display: flex;
    line-height: 1;
    .select-list {
        &.active {
            border: 1px solid #349ffd;
        }
        width: 80px;
        height: 80px;
        background-color: #fff;
        position: relative;
        & + .select-list {
            margin-left: 8px;
        }
    }
    .iconfont {
        position: absolute;
        bottom: 4px;
        right: 4px;
        z-index: 9;
        color: #dd2020;
        cursor: pointer;
    }
}
.active {
    color: #349ffd;
}
.text_set_cate {
    padding: 0 10px;
}
.huazi_box {
    display: flex;
    flex-wrap: wrap;
    .huazi_list {
        &.active {
            .huazi_img {
                border: 2px solid #349ffd;
            }
        }
        height: 74px;
        width: 0;
        flex: 0 0 33.33%;
        padding: 6px;
        box-sizing: border-box;
        text-align: center;
        .huazi_img {
            border-radius: 6px;
            height: 100%;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
        }
    }
}
.txt_del {
    padding: 0 10px;
    color: #707070;
    &:hover {
        color: #000;
    }
}
.tab_cate {
    display: flex;
    li {
        position: relative;
        padding-bottom: 4px;
        & + li {
            margin-left: 20px;
        }
    }
    .active {
        color: #349ffd;
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #349ffd;
        }
    }
}
.img_library {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.tab_content {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-start;
    flex: 1;
    height: 0;
    overflow: auto;
}
.img_box {
    flex: 0 0 25%;
    width: 0;
    height: 150px;
    // background-color: #f3f3f3;
    padding: 8px;
    position: relative;

    .iconfont {
        position: absolute;
        bottom: 10px;
        right: 14px;
        z-index: 9;
        color: #349ffd;
        cursor: pointer;
    }
}
.page_box {
    height: 80px;
    padding-top: 20px;
}
.impo_btn {
    &:hover {
        background-color: #349ffd;
        color: #fff;
    }
    &:active {
        background-color: #5aa5e7;
    }
    height: 40px;
    line-height: 40px;
    width: 110px;
    text-align: center;
    background-color: #f5f6f8;
    color: #8590b3;
    border-radius: 5px;
    cursor: pointer;
}
</style>
<template>
    <div class="area">
        <div class="edit">
            <div class="hd">
                <TABS v-model="active" :data="tab_list" />
            </div>
            <div class="body">
                <div class="identify-box" v-if="active === 0">
                    <div
                        class="txt_set_content"
                        v-if="txtTrack && txtTrack.length"
                    >
                        <div class="txt_list">
                            <div class="text-set-hd">
                                <div class="txt-title">
                                    字幕文本检查，修改后将复用于所有含该字幕的组合
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="addTxt"
                                    >添加字幕</el-button
                                >
                            </div>
                            <div class="captions_box">
                                <!-- <div v-for="(item, index) in test" :key="index">
                                    <el-checkbox v-model="item.checked">{{
                                        item.checked
                                    }}</el-checkbox>
                                </div> -->
                                <div
                                    class="txt-group"
                                    ref="txt_box"
                                    v-for="(item, index) in txtTrack"
                                    :key="index"
                                >
                                    <div class="txt-list">
                                        <div class="check-box">
                                            <el-checkbox
                                                v-model="item.checked"
                                                @change="changeTxt(item, index)"
                                            ></el-checkbox>
                                        </div>
                                        <div class="txted" ref="txt_item">
                                            <el-input
                                                size="mini"
                                                v-model="item.Content"
                                                placeholder="请输入内容"
                                                @input="upTxt(item, index)"
                                            ></el-input>
                                        </div>
                                        <div
                                            class="txt_del"
                                            @click="delTxt(item, index)"
                                        >
                                            <i
                                                class="iconfont icon-a-shanchu"
                                                title="删除字幕"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text_set_content">
                            <div class="txt-sets">
                                <div class="text-set-hd">
                                    <div
                                        class="text_set_cate"
                                        :class="
                                            text_active == 1 ? 'active' : ''
                                        "
                                        @click="text_active = 1"
                                    >
                                        基础设置
                                    </div>
                                    <div
                                        class="text_set_cate"
                                        :class="
                                            text_active == 2 ? 'active' : ''
                                        "
                                        @click="text_active = 2"
                                    >
                                        花字设置
                                    </div>
                                    <div
                                        class="text_set_cate"
                                        :class="
                                            text_active == 3 ? 'active' : ''
                                        "
                                        @click="text_active = 3"
                                    >
                                        动画设置
                                    </div>
                                </div>
                                <div class="set-box" v-show="text_active == 1">
                                    <div class="set_row">
                                        <div class="set-lable">字体</div>
                                        <el-select
                                            v-model="fontStyle.Font"
                                            placeholder="请选择"
                                            @change="setfont('Font')"
                                            size="mini"
                                            class="font_select"
                                        >
                                            <el-option
                                                v-for="item in typeface"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>

                                        <div class="set-lable">大小</div>
                                        <el-input-number
                                            size="mini"
                                            class="size_input"
                                            v-model="fontStyle.FontSize"
                                            controls-position="right"
                                            @change="setfont('FontSize')"
                                            :min="12"
                                            :max="80"
                                        ></el-input-number>
                                        <i
                                            class="iconfont icon-a-rongqi103"
                                            @click="setFontFace('Bold')"
                                            :class="
                                                fontStyle.Bold ? 'active' : ''
                                            "
                                        ></i>
                                        <i
                                            class="iconfont icon-a-rongqi105"
                                            :class="
                                                fontStyle.Italic ? 'active' : ''
                                            "
                                            @click="setFontFace('Italic')"
                                        ></i>
                                        <i
                                            class="iconfont icon-xieti"
                                            :class="
                                                fontStyle.Underline
                                                    ? 'active'
                                                    : ''
                                            "
                                            @click="setFontFace('Underline')"
                                        ></i>
                                    </div>

                                    <!-- <div class="set_row">
                                        <div class="set-lable">文本对齐</div>
                                        <div class="align">
                                            <i
                                                class="iconfont icon-a-zu1787"
                                            ></i>
                                            <i
                                                class="iconfont icon-a-zu1792"
                                            ></i>
                                            <i
                                                class="iconfont icon-youduiqi2"
                                            ></i>
                                        </div>
                                    </div> -->
                                    <div class="set_row">
                                        <div class="set-lable">文本位置</div>
                                        <div
                                            class="align slider_box"
                                            style="flex: 1"
                                        >
                                            <!-- <i class="iconfont icon-a-zu1790"></i>
                                        <i class="iconfont icon-a-zu1788"></i>
                                        <i class="iconfont icon-xiaduiqi2"></i> -->
                                            <el-slider
                                                v-model="fontStyle.Y"
                                                :step="0.01"
                                                :min="10"
                                                :max="90"
                                                @change="setTxtAdd"
                                            ></el-slider>
                                        </div>
                                    </div>
                                    <div class="set_row">
                                        <div class="set-lable">颜色</div>
                                        <el-color-picker
                                            v-model="fontStyle.Color"
                                            @change="setfont('Color')"
                                            size="mini"
                                        ></el-color-picker>
                                    </div>
                                    <div class="set_row">
                                        <div class="set-lable">投影设置</div>
                                        <el-color-picker
                                            v-model="fontStyle.BackColour"
                                            @change="setfont('BackColour')"
                                            size="mini"
                                        ></el-color-picker>
                                        <el-input-number
                                            size="mini"
                                            class="size_input"
                                            v-model="fontStyle.Shadow"
                                            controls-position="right"
                                            @change="setfont('Shadow')"
                                            :min="0"
                                            :max="3"
                                        ></el-input-number>
                                    </div>
                                    <div class="set_row">
                                        <div class="set-lable">描边设置</div>
                                        <el-color-picker
                                            v-model="fontStyle.OutlineColour"
                                            @change="setfont('OutlineColour')"
                                            size="mini"
                                        ></el-color-picker>
                                        <el-input-number
                                            size="mini"
                                            class="size_input"
                                            v-model="fontStyle.Outline"
                                            controls-position="right"
                                            @change="setfont('Outline')"
                                            :min="0"
                                            :max="3"
                                        ></el-input-number>
                                    </div>
                                    <div class="txt-sets">
                                        <div class="text-set-hd">
                                            时间位置 进点:{{
                                                timeAdd[0]
                                            }}
                                            出点:{{ timeAdd[1] }}
                                        </div>
                                        <div class="set-body">
                                            <div class="slider_box">
                                                <el-slider
                                                    v-model="timeAdd"
                                                    :step="0.01"
                                                    range
                                                    :max="timeSlot[1]"
                                                    :min="timeSlot[0]"
                                                    :key="sliderKey"
                                                    @change="changeTimeSlot"
                                                >
                                                </el-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="set-box" v-show="text_active == 2">
                                    <div class="huazi_box">
                                        <div
                                            class="huazi_list"
                                            v-for="(item, index) in huazi"
                                            :key="index"
                                            @click="setHuaZi(item, index)"
                                            :class="
                                                huazi_active == index
                                                    ? 'active'
                                                    : ''
                                            "
                                        >
                                            <div class="huazi_img">
                                                <el-image
                                                    style="
                                                        width: 100%;
                                                        height: 100%;
                                                    "
                                                    :src="item.url"
                                                    fit="contain"
                                                ></el-image>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="set-box" v-show="text_active == 3">
                                    <div class="txt_effect_box">
                                        <div class="effect_tab">
                                            <div
                                                class="effect_tab_list"
                                                :class="
                                                    effect_tab == 1
                                                        ? 'active'
                                                        : ''
                                                "
                                                @click="effect_tab = 1"
                                            >
                                                进场
                                            </div>
                                            <div
                                                class="effect_tab_list"
                                                :class="
                                                    effect_tab == 2
                                                        ? 'active'
                                                        : ''
                                                "
                                                @click="effect_tab = 2"
                                            >
                                                出场
                                            </div>
                                        </div>
                                        <div class="txt_effect_body">
                                            <div
                                                class="effect_group"
                                                v-show="effect_tab == 1"
                                            >
                                                <div
                                                    class="effect_list"
                                                    v-for="(
                                                        item, index
                                                    ) in effect_text.in"
                                                    :key="index"
                                                    @click="
                                                        setFontEffect(
                                                            item,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <div class="effect_img">
                                                        <el-image
                                                            style="width: 100%"
                                                            :src="item.url"
                                                            fit="contain"
                                                        ></el-image>
                                                    </div>
                                                    <div class="effect_label">
                                                        {{ item.label }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="effect_group"
                                                v-show="effect_tab == 2"
                                            >
                                                <div
                                                    class="effect_list"
                                                    v-for="(
                                                        item, index
                                                    ) in effect_text.out"
                                                    :key="index"
                                                    @click="
                                                        setFontEffect(
                                                            item,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <div class="effect_img">
                                                        <el-image
                                                            style="width: 100%"
                                                            :src="item.url"
                                                            fit="contain"
                                                        ></el-image>
                                                    </div>
                                                    <div class="effect_label">
                                                        {{ item.label }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 视频识别字体 -->
                    <div class="identify-tis" v-else>
                        <div>
                            <div>
                                已选<span class="video-num">{{
                                    selects.length
                                }}</span
                                >个视频组合
                            </div>
                            <div>将识别组合中的人声并生成字幕文本</div>
                            <div class="identify-btn"></div>
                            <el-button
                                type="primary"
                                size="small"
                                round
                                @click="sendCommand"
                                >识别字幕</el-button
                            >
                            <el-button
                                type="primary"
                                size="small"
                                round
                                @click="addTxt"
                                >添加字幕</el-button
                            >
                        </div>
                    </div>
                </div>
                <!-- 滤镜 -->
                <div v-else-if="active === 1" class="filter-box">
                    <div class="fliter_selects">
                        <div class="fliter_select_group">
                            <div
                                class="list"
                                v-for="(item, index) in filter_data"
                                :key="index"
                                :class="
                                    item.value === filter_selected
                                        ? 'active'
                                        : ''
                                "
                                @click="changeFilter(item, index)"
                            >
                                <div class="img_box">
                                    <el-image
                                        style="width: 100%; height: 100%"
                                        :src="item.img"
                                        fit="cover"
                                    ></el-image>
                                </div>
                                <div class="fliter_label">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 贴纸 -->
                <div v-else class="sticker-box">
                    <div class="impo_btn" @click="showLibrary = true">
                        <i class="el-icon-circle-plus"></i>
                        添加贴纸
                    </div>
                    <div class="sticker-group">
                        <div
                            class="sticker-list"
                            v-for="(item, index) in imgTrack"
                            :key="index"
                            @click="setImg(item)"
                            :class="select_img == item ? 'active' : ''"
                        >
                            <el-image
                                style="width: 100px; height: 100px"
                                :src="item.Src"
                                fit="cover"
                            ></el-image>
                            <span
                                class="iconfont icon-lujing1"
                                title="点击删除贴纸"
                                @click.stop="del_sticker(item, index)"
                            ></span>
                        </div>
                    </div>
                    <div class="sticker-selects" v-if="select_img">
                        <div>
                            进点：{{ stickerTimer[0] }} 出点：{{
                                stickerTimer[1]
                            }}
                        </div>
                        <div class="stickerTimer">
                            <el-slider
                                v-model="stickerTimer"
                                range
                                :step="0.01"
                                :min="timeSlot[0]"
                                :max="timeSlot[1]"
                                :key="sliderKey"
                                @change="upImgtime"
                            >
                            </el-slider>
                        </div>
                        <!-- <div class="select-group">
                            <div
                                class="select-list"
                                v-for="(item, index) in imgTrack"
                                :key="index"
                                :class="select_img == item ? 'active' : ''"
                                @click="setImg(item)"
                            >
                                <span
                                    class="iconfont icon-lujing1"
                                    title="点击删除贴纸"
                                    @click.stop="del_sticker(item, index)"
                                ></span>
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.Src"
                                    fit="cover"
                                ></el-image>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="foo">
                <div class="count_box">
                    共选择了<span>{{ selects.length }}</span
                    >组合
                </div>
                <div class="btn" @click="save_group">合成下载</div>
            </div>
        </div>
        <el-drawer :visible.sync="showLibrary" :size="620" direction="ltr">
            <div slot="title">
                <ul class="tab_cate">
                    <li
                        :class="library_cate == 1 ? 'active' : ''"
                        @click="library_tab(1)"
                    >
                        系统图库
                    </li>
                    <li
                        :class="library_cate == 2 ? 'active' : ''"
                        @click="library_tab(2)"
                    >
                        我的图库
                    </li>
                </ul>
            </div>
            <div class="img_library">
                <div
                    class="tab_content"
                    v-loading="getImgLoging"
                    element-loading-text="正在获取素材..."
                >
                    <!-- <div class="img_box" v-for="index in 6" :key="index">
                    {{ index }}
                </div> -->
                    <div class="img_box" v-for="img in imgs" :key="img.MediaId">
                        <el-image
                            style="
                                width: 100%;
                                height: 100%;
                                background-color: #f3f3f3;
                            "
                            :src="img.url"
                            fit="contain"
                        >
                        </el-image>
                        <span
                            class="iconfont icon-lujing"
                            title="点击添加贴纸"
                            @click="addImage(img)"
                        ></span>
                    </div>
                </div>
                <div class="page_box">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="20"
                        :current-page.sync="page_curren"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import filter_data from "@/assets/js/filter";
import huazi from "@/assets/js/huazi";
import fontConfig from "@/assets/js/fontConfig";
import effect_text from "@/assets/js/effect_text";
import TABS from "@/components/tabs";
import { mapGetters } from "vuex";
import { getStickerImg, getHuazi } from "@/api/engi";
import { getSource } from "@/api/api";
export default {
    name: "edit",
    components: { TABS },
    props: ["combination", "selects", "edits"],
    data() {
        return {
            //new
            // 文字特效类型，1进场，2出场
            effect_tab: 1,
            //是否显示图库
            showLibrary: false,
            // 视频文字内容
            txtTrack: "",
            // 视频图片内容
            imgTrack: "",
            // 选中字幕列表
            select_txt_list: [],
            currTime: 0, //当前播放时间
            total: 0,
            page_size: 20,
            page_curren: 1,
            library_cate: 1,
            getImgLoging: false,
            //end
            y: 0,
            text_active: 1,
            huazi_active: 0,
            huazi: [
                {
                    label: "无",
                    url: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/wu.png",
                    value: "",
                },
            ],
            fonts: [
                {
                    label: "宋体",
                    value: "SimSun",
                },
                {
                    label: "仿宋",
                    value: "fangsong",
                },
                {
                    label: "楷体",
                    value: "KaiTi",
                },
                {
                    label: "思源黑体",
                    value: "siyuan-heiti",
                },
            ],
            typeface: fontConfig,
            active: 0,
            maxTime: 30,
            timeLine: "",
            filter_selected: "",
            // currentTime: 0, //当前播放时间
            fliter_adjust: {
                fliter_timer: [0, 30],
                brightness: 0, //亮度 -255-255
                contrast: 0, //对比度 -100-100
                saturation: 0, //饱和度 -100-100
                tint: 0, //色调 -100-100
            },
            tab_list: [
                { name: "字幕", icon: "iconfont icon-zimu" },
                { name: "滤镜", icon: "iconfont icon-lvjing" },
                { name: "装饰", icon: "iconfont icon-zhuangshiceng" },
            ],
            imgs: [],
            filter_data,
            face_select: "",
            status: 1, //1,等待，2识别中，3编辑，
            isSelect: 0,
            checked: 0,
            select_data: [],
            tracks: [],
            texts: [], //字幕

            // 滤镜参数
            VideoTrack: {
                Id: 3,
                Type: "Effect",
                Visible: true,
                Count: 1,
                VideoTrackClips: [
                    {
                        Type: "Filter",
                        Id: 9,
                        TrackId: 3,
                        Index: 138,
                        SubType: "pfc",
                        Name: "filters.pfc",
                        CoverUrl:
                            "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg",
                        Properties: {},
                        Category: "film",
                        EffectType: "filter",
                        EffectConfig: {
                            Image: "https://ice-pub-media.myalicdn.com/websdk/lut/32/PFC.png",
                        },
                        TimelineIn: 0,
                        TimelineOut: 100,
                        Duration: 100,
                        X: 0,
                        Y: 0,
                        Width: 0,
                        Height: 0,
                        CanScale: false,
                        ExtParams: "effect=pfc",
                    },
                ],
            },
            fontStyle: {
                Color: "#ffffff",
                Content: "文本",
                Font: "SimSun",
                FontSize: 20,
                TimelineIn: 0,
                TimelineOut: 5,
                Type: "Text",
                _x: "middle",
                Y: 90,
                clipIndex: 0,
                FontFace: { Bold: false, Italic: false, Underline: false },
                Bold: false,
                Italic: false,
                Underline: false,
                subtitleWidth: 1080,
                previewId: "1689091904362348545",
                y: 10,
            },
            timeSlot: [0, 100], // 时间可选区间
            timeAdd: [0, 0], //素材出现时间位置
            selectTxt: [], //当前编辑的字幕
            playTime: 0, //视频组合时长
            sliderKey: 0,
            stickerTimer: [0, 0],
            img_timer: [],
            // 选中的图片
            select_img: "",

            // 特效数据
            effect_text,
        };
    },
    watch: {
        // combination(data) {
        //     // this.resetElement(data);
        //     // console.log(data);
        // },
        // selects(data) {
        //     // console.log(data);
        // },
        edits(val) {
            let edits = JSON.parse(val);
            edits.txts.forEach((item) => {
                console.log(item);
                item.Y = item.Y * 100;
                item.AaiMotionInEffect = item.AaiMotionInEffect || "";
                item.AaiMotionIn = 0;
                item.AaiMotionOutEffect = item.AaiMotionOutEffect || "";
                item.AaiMotionOut = 0;
            });
            // console.log(edits.txts);
            this.txtTrack = edits.txts;
            edits.images.forEach((item) => {
                item.Y = item.Y * 100;
                item.X = item.X * 100;
            });
            this.imgTrack = edits.images;
            // console.log(this.imgTrack);
        },
        // page_curren() {
        //     this.getImgList();
        // },
    },
    mounted() {
        // 接收视频时长
        Bus.$on("playtime", (res) => {
            // console.log("视频时长：" + res);
            this.timeSlot[1] = res;
            this.playTime = res;
            // this.currentTime = res;
        });
        // 接收当前播放时长
        Bus.$on("playcurren", (res) => {
            this.currTime = res;
        });
        getHuazi().then((res) => {
            console.log(res.data.records);
            this.huazi = this.huazi.concat(res.data.records);
            console.log(this.huazi);
            res.data.records.map((item) => {
                item.label = item.contentType;
                item.value = item.contentType;
                item.url = item.coverUrl;
                this.huazi.push(item);
            });
        });
        this.getImgList();
    },
    beforeDestroy() {
        Bus.$off();
    },
    methods: {
        save_group() {
            this.$emit("save_group", true);
        },
        setTxtY(y) {
            this.setfont();
        },
        setFontFace(type) {
            this.fontStyle[type] = this.fontStyle[type] ? false : true;
            this.setfont();
        },
        resetElement(data) {
            if (!data) return;
            // 提取字幕，滤镜，贴纸
            let edits = {
                txts: [],
                images: [],
                filters: [],
            };
            let line = JSON.parse(data);
            line.timeLine.VideoTracks.forEach((item) => {
                switch (item.Type) {
                    case "Subtitle":
                        edits.txts.push(item);
                        break;
                    case "Effect":
                        edits.filters.push(item);
                        this.filter_selected = item.VideoTrackClips[0].SubType;
                        break;
                    case "Image":
                        item.VideoTrackClips.forEach((list) => {
                            edits.images.push(list);
                        });

                        break;
                    default:
                        break;
                }
            });
            console.log("更新内容");
            this.edits = edits;
            Bus.$emit("edits", edits);
        },

        //删除贴纸
        del_sticker(item, index) {
            // console.log(item);
            this.imgTrack.splice(index, 1);
            this.$parent.delImg(item);
        },

        //------------------------------------ news
        library_tab(val) {
            if (this.library_cate != val) {
                this.library_cate = val;
                this.page_curren = 1;
                // 获取列表
                this.getImgList();
            }
        },
        getImgList() {
            this.imgs = [];
            this.getImgLoging = true;
            this.library_cate == 1 ? this.getSysImg() : this.getUserImg();
        },
        async getUserImg() {
            // 获取用户图库
            let params = {
                projectType: "Material",
                currentPage: this.page_curren,
                pageSize: this.page_size,
                contentType: "image",
            };
            getSource(params).then((res) => {
                this.getImgLoging = false;
                this.total = res.data.pagination.total;
                let arr = [];
                res.data.list.map((list) => {
                    let item = {};
                    item.url = list.coverUrl;
                    item.name = list.fileName;
                    item.MediaId = list.mediaId;
                    item.width = list.width;
                    item.height = list.height;
                    item.type = list.contentType;
                    arr.push(item);
                });
                this.imgs = arr;
            });
        },
        async getSysImg() {
            getStickerImg({
                Action: "ListPublicMediaBasicInfos",
                IncludeFileBasicInfo: true,
                MediaTagId: "sticker-bubble",
                PageNo: this.page_curren,
                PageSize: this.page_size,
            }).then((res) => {
                this.getImgLoging = false;
                this.total = res.TotalCount;
                var arr = [];
                res.MediaInfos.map((list) => {
                    let item = {};
                    item.url = list.MediaBasicInfo.InputURL;
                    item.name = list.MediaBasicInfo.Title;
                    item.MediaId = list.MediaBasicInfo.MediaId;
                    item.width = 200;
                    item.height = 200;
                    item.type = item.contentType;
                    arr.push(item);
                });
                // console.log(arr);
                this.imgs = arr;
            });
        },
        // 添加贴纸
        addImage(item) {
            console.log(item);
            let currTime = this.currTime;
            let img_list = {
                Type: "Image",
                Id: Date.parse(new Date()) + Math.floor(Math.random() * 100),
                TrackId: 1,
                MediaId: item.MediaId,
                Src: item.url,
                TimelineIn: currTime,
                TimelineOut: currTime + 5,
                Duration: 15,
                DyncFrames: item.type == "image/gif" ? 30 : 0,
                X: 0.5,
                Y: 0.5,
                Width: 0.185,
                Height: 0.104,
                BusinessType: "Sticker",
            };
            this.$parent.addImg(img_list);
        },
        upImgtime(val) {
            // console.log(val);
            let imgClip = this.select_img;
            imgClip.TimelineIn = val[0];
            imgClip.TimelineOut = val[1];
            // console.log(imgClip);
            let timer = {
                id: this.select_img.Id,
                times: val,
            };

            Bus.$emit("ImgTimeSet", timer);
        },
        setImg(data) {
            // console.log(data);
            if (this.select_img === data) {
                this.select_img = "";
            } else {
                this.select_img = data;
                this.sliderKey = Math.random();
                this.stickerTimer[0] = data.TimelineIn;
                this.stickerTimer[1] = data.TimelineOut;
                Bus.$emit("setPlayTime", data.TimelineIn);
            }
        },
        // 滤镜
        // 字幕设置
        setfont(type, val) {
            // 当前设置的参数
            let fontStyle = this.fontStyle;
            let txtTrack = this.txtTrack;
            txtTrack.forEach((item, index) => {
                if (item.checked) {
                    // 修改
                    switch (type) {
                        case "Font":
                            item.Font = fontStyle.Font;
                            // console.log("修改字幕");
                            // 字体
                            break;

                        case "FontSize":
                            item.FontSize = fontStyle.FontSize;
                            // 字体大小
                            break;

                        case "Color":
                            item.Color = fontStyle.Color;
                            console.log("颜色");
                            // 字体大小
                            break;
                        case "BackColour":
                            item.BackColour = fontStyle.BackColour;
                            console.log("阴影颜色");
                            console.log(item);
                            // 阴影颜色
                            break;
                        case "Shadow":
                            item.Shadow = fontStyle.Shadow;
                            console.log("阴影大小");
                            console.log(item);
                            // 阴影设置
                            break;
                        case "Outline":
                            item.Outline = fontStyle.Outline;
                            console.log("字体边线大小");
                            // 字体边线大小
                            break;
                        case "OutlineColour":
                            item.OutlineColour = fontStyle.OutlineColour;
                            console.log("字体边线颜色");
                            // 字体边线颜色
                            break;
                        case "txtAdd":
                            item.Y = val;
                            // console.log("位置");
                            // let size = fontStyle.FontSize;
                            // 字体大小
                            break;
                        case "timerAdd":
                            item.TimelineIn = val[0];
                            item.TimelineOut = val[1];
                            // console.log("位置");
                            // let size = fontStyle.FontSize;
                            // 字体大小
                            break;
                        case "huazi":
                            if (val.value) {
                                item.EffectColorStyle = val.value;
                                item.Outline = 2;
                                item.Shadow = 2;
                            } else {
                                item.EffectColorStyle = "";
                                item.Outline = "";
                                item.Shadow = "";
                            }
                            break;
                        case "effect":
                            // console.log(item);
                            if (val.type == "in") {
                                item.AaiMotionInEffect = val.value;
                                item.AaiMotionIn = 0.5;
                            }
                            if (val.type == "out") {
                                item.AaiMotionOutEffect = val.value;
                                item.AaiMotionOut = 0.5;
                            }
                            break;
                        default:
                            break;
                    }
                    // console.log(item);
                    this.$parent.textEdit(item);
                }
            });
        },
        // 设置花字
        setHuaZi(item, index) {
            console.log(item);
            this.huazi_active = index;
            this.setfont("huazi", item);
        },
        // 设置字幕特效
        setFontEffect(item) {
            // console.log(item);
            this.setfont("effect", item);
        },
        // 调整字幕位置
        setTxtAdd(val) {
            // console.log(val);
            this.setfont("txtAdd", val);
        },
        // 调整字幕出现时间段
        changeTimeSlot(val) {
            // Bus.$emit("setPlayTime", val[]);
            // console.log(val);
            this.setfont("timerAdd", val);
        },
        changeFilter(info, index) {
            let val = info.value;
            this.filter_selected = val;
            this.$emit("addFilter", val);
        },
        //识别字幕
        sendCommand() {
            this.$emit("getCaptions");
        },
        // 添加字幕
        addTxt() {
            let currTime = this.currTime;
            let trackClips = {
                Color: "#ffffff",
                Content: "添加字幕",
                Font: "SimSun",
                FontSize: 20,

                Id: Math.ceil(Math.random() * 1000000),
                TimelineIn: currTime,
                TimelineOut: currTime + 5,
                Type: "Text",
                _x: "middle",
                // _y: "bottom",
                Y: 0.9,
                clipIndex: 0,
                FontFace: {
                    Bold: false,
                    Italic: false,
                    Underline: false,
                },
                Bold: false,
                Italic: false,
                Underline: false,
                subtitleWidth: 1080,
                // Outline: 2, //字体边线
                // OutlineColour: "#000000", //边线颜色
                // Shadow: 2, //投影大小
                // BackColour: "#ff0000", //投影颜色
            };
            // this.edits.txts.push(trackClips);
            console.log("更新字幕轨道");
            this.txtTrack.push(trackClips);
            this.$emit("addTxt", trackClips);
        },
        // 删除字幕
        delTxt(item, index) {
            this.$confirm("确定要删除此字幕？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$emit("delTxt", item);
                this.txtTrack.splice(index, 1);
            });
        },
        // 修改字幕
        upTxt(res) {
            this.$parent.textEdit(res);
        },

        // 字幕选择编辑
        changeTxt(item, index) {
            this.fontStyle = item;
            this.timeAdd[0] = item.TimelineIn;
            this.timeAdd[1] = item.TimelineOut;
            this.sliderKey = Math.random();
            Bus.$emit("setPlayTime", item.TimelineIn);
        },
    },
};
</script>

<style lang="less" scoped>
.effect_label {
    font-size: 12px;
    text-align: center;
}
.txt_effect_box {
    .effect_tab {
        display: flex;
        .effect_tab_list {
            font-size: 12px;
            padding: 2px 10px;
            border-radius: 2%;
            &.active {
                background-color: #349ffd;
                color: #fff;
            }
        }
    }
    .txt_effect_body {
        .effect_group {
            display: flex;
            flex-wrap: wrap;
            .effect_list {
                flex: 0 0 25%;
                width: 0;
                padding: 4px;
            }
            .effect_img {
                border-radius: 4px;
                overflow: hidden;
                line-height: 0;
            }
        }
    }
}
.foo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    .count_box {
        line-height: 26px;
        color: #334681;
        span {
            color: #349ffd;
            font-weight: bold;
            padding: 0 2px;
        }
    }
    .btn {
        width: 185px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        color: #fff;
        background: linear-gradient(308deg, #24e4ee 0%, #349ffd 100%);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        cursor: pointer;
    }
}
.edit {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.body {
    flex: 1;
    height: 0;

    padding: 0 15px;
    overflow: hidden;
}
.hd,
.foo {
    // height: 100px;
}
.hd {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tabs-box {
        display: flex;
        background-color: #f5f6f8;
        border-radius: 2px;
        padding: 2px 3px;
        font-size: 12px;
        li {
            padding: 3px 12px;
            cursor: pointer;
            &.active {
                background-color: #fff;
                color: #349ffd;
            }
            & + li {
                margin-left: 3px;
            }
        }
        .iconfont {
            color: #8597b2;
        }
    }
}
.identify-box {
    height: 100%;
    overflow: hidden;
    .txt_set_content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .text_set_content {
        flex: 1;
        height: 0;
        overflow: auto;
        margin-top: 10px;
    }
    .txt_list,
    .txt-sets {
        background-color: #f0f8ff;
    }
    .txt_list {
        flex: 1;
        max-height: 280px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .captions_box {
            flex: 1;
            height: 0;
            overflow: auto;
        }
    }
    .txt-sets {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        .set-box {
            flex: 1;
            height: 0;
            overflow: auto;
        }
    }
    .identify-tis {
        min-height: 364px;
        text-align: center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        .video-num {
            padding: 0 4px;
            color: #349ffd;
        }
    }
    .identify-btn {
        margin-top: 14px;
    }
}
.text-set-hd {
    padding: 8px 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-bottom: 2px solid #fff;
    .txt-title {
        margin-right: 8px;
    }
}
.txt-group {
    padding: 8px 0;
}
.txt-list {
    padding: 8px 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .txted {
        margin-left: 6px;
        flex: 1;
        div {
            padding: 4px 6px;
            background-color: #fff;
            &[contenteditable="true"] {
                // outline: 1px #5196b6 solid;
                outline: none;
            }
        }
    }
}
.set-box {
    padding: 10px;
    flex: 1;
}
.set_row {
    display: flex;
    align-items: center;
    padding: 4px 0;
    .set-lable {
        font-size: 12px;
        width: 60px;
        text-align: center;
        // margin: 0 10px;
    }
    .size_input {
        width: 80px;
    }
    .font_select {
        width: 80px;
    }
    .align {
        .iconfont {
            margin-left: 6px;
            font-size: 12px;
        }
    }
}
.slider_box {
    padding: 0 10px;
    box-sizing: border-box;
}
</style>