export default {
    in: [
        {
            label: '无',
            value: '',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/wu.png'
        },
        {
            label: '模糊入场',
            value: 'blur_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/blur_in.webp'
        },
        {
            label: '波浪弹入',
            value: 'wave_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/wave_in.webp'
        },
        {
            label: '羽化向右擦开',
            value: 'scroll_right_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/scroll_right_in.webp'
        },
        {
            label: '羽化向左擦开',
            value: 'scroll_left_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/scroll_left_in.webp'
        },
        {
            label: '心形弹跳',
            value: 'heartjump_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/heartjump_in.webp'
        },
        {
            label: '音符弹跳',
            value: 'musicjump_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/musicjump_in.webp'
        },
        {
            label: '螺旋上升',
            value: 'rotateup_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/rotateup_in.webp'
        },
        {
            label: '收拢',
            value: 'close_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/close_in.webp'
        },
        {
            label: '弹弓',
            value: 'slingshot_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/slingshot_in.webp'
        },
        {
            label: '空翻',
            value: 'rotateflip_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/rotateflip_in.webp'
        },
        {
            label: '弹性伸缩',
            value: 'elasticzoom_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/elasticzoom_in.webp'
        },
        {
            label: '弹入',
            value: 'zoom_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/zoom_in.webp'
        },
        {
            label: '弹簧',
            value: 'spring_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/spring_in.webp'
        },
        {
            label: '渐显',
            value: 'fade_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/fade_in.webp'
        },
        {
            label: '生长',
            value: 'growth_in',
            type: 'in',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/in/growth_in.webp'
        }
    ],
    out: [
        {
            label: '无',
            value: '',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/wu.png'
        },
        {
            label: '模糊',
            value: 'blur_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/blur_out.webp'
        },
        {
            label: '波浪弹出',
            value: 'wave_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/wave_out.webp'
        },
        {
            label: '羽化向右擦除',
            value: 'scroll_right_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/scroll_right_out.webp'
        },
        {
            label: '羽化向左擦除',
            value: 'scroll_left_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/scroll_left_out.webp'
        },
        {
            label: '螺旋下降',
            value: 'rotatedown_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/rotatedown_out.webp'
        },
        {
            label: '弹弓',
            value: 'slingshot_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/slingshot_out.webp'
        },
        {
            label: '展开',
            value: 'open_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/open_out.webp'
        },
        {
            label: '空翻',
            value: 'rotateflip_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/rotateflip_out.webp'
        },
        {
            label: '弹性伸缩',
            value: 'elasticzoom_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/elasticzoom_out.webp'
        },
        {
            label: '弹簧',
            value: 'spring_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/spring_out.webp'
        },
        {
            label: '弹出',
            value: 'zoom_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/zoom_out.webp'
        },
        {
            label: '渐隐',
            value: 'fade_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/fade_out.webp'
        },
        {
            label: '生长',
            value: 'growth_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/growth_out.webp'
        },
        {
            label: '闭幕',
            value: 'close_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/close_out.webp'
        },
        {
            label: '溶解',
            value: 'dissolve_out',
            type: 'out',
            url: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/font_effects/out/dissolve_out.webp'
        }
    ]
}