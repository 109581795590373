<style lang="less" scoped>
.area {
    height: 100%;
}
.edit_area {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // box-sizing: border-box;
    // border: 1px solid #f00;
}
.edit_box {
    background-color: rgba(255, 0, 0, 0.3);
    color: #fff;
}
/deep/ .show_play {
    display: flex;
    flex-direction: column;
    .el-dialog__body {
        flex: 1;
        padding: 0;
        background-color: #fff;
    }
}
.resize_box {
    width: 100%;
    height: 100%;
    position: relative;
}
.play_body {
    height: 100%;
}
.track_box {
    position: absolute;
    z-index: 9;
    // background-color: rgba(255, 0, 0, 0.6);
}
</style>

<template>
    <div class="area">
        <div class="play">
            <div class="play-box" ref="play_box">
                <div
                    class="edit_area"
                    :style="{
                        width: Math.ceil(paly_area.w) + 'px',
                        height: Math.ceil(paly_area.h) + 'px',
                    }"
                >
                    <div
                        class="txt"
                        style="
                            position: absolute;
                            font-size: 20pt;
                            font-family: SimSun;
                        "
                    ></div>
                    <div class="resize_box">
                        <div
                            @click="showResize(image)"
                            v-for="image in imgClips"
                            :key="image.Id"
                            class="track_box"
                            :style="{
                                width: image.Width * 100 + '%',
                                height: image.Height * 100 + '%',
                                top: image.Y * 100 + '%',
                                left: image.X * 100 + '%',
                            }"
                        ></div>
                        <!-- <template v-for="(val, key) in editTrack">
                            <template v-if="key === 'images'">
                                <div
                                    @click="showResize(image)"
                                    v-for="(image, i) in imgClips"
                                    :key="i"
                                    class="track_box"
                                    :style="{
                                        width: image.Width * 100 + '%',
                                        height: image.Height * 100 + '%',
                                        top: image.Y * 100 + '%',
                                        left: image.X * 100 + '%',
                                    }"
                                ></div>
                            </template>
                        </template> -->
                        <VueDragResize
                            v-show="moveAdd.isShow"
                            :key="moveAdd.radom"
                            style="z-index: 99"
                            :isActive="true"
                            @resizing="resize"
                            @dragging="resize"
                            @dragstop="dragstop"
                            @resizestop="dragstop"
                            @activated="hideResize"
                            @deactivated="deactivated"
                            :parentW="Math.ceil(paly_area.w)"
                            :parentH="Math.ceil(paly_area.h)"
                            :parent-limitation="true"
                            :aspectRatio="true"
                            :isResizable="true"
                            :w="parseInt(moveAdd.width)"
                            :h="moveAdd.height"
                            :x="moveAdd.x"
                            :y="moveAdd.y"
                        >
                            <img :src="selectTrack.Src" alt="" width="100%" />
                        </VueDragResize>
                    </div>
                </div>
                <div
                    class="video"
                    v-loading="isLoading"
                    element-loading-text="正在生成预览视频"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 255, 255, 0.8)"
                    ref="video_box"
                >
                    <div
                        id="player-wrapper"
                        :style="{ height: paly_area.h + 'px' }"
                    ></div>
                </div>
            </div>
            <div class="play-control">
                <div class="schedule">
                    <el-slider
                        :step="0.01"
                        v-model="currTime"
                        :max="duration"
                        @change="setPlayTime"
                    ></el-slider>
                </div>
                <div class="control_row">
                    <div class="control_time lr">
                        {{ currTime }}s / {{ duration }}s
                    </div>
                    <div class="play_btn">
                        <i
                            class="iconfont icon-bofangjian"
                            @click="play()"
                            v-if="!getPlayTimer"
                        ></i>
                        <i
                            class="iconfont icon-zanting"
                            @click="pause()"
                            v-else
                        ></i>
                    </div>
                    <!-- <div class="play_mode lr">
                        <div @click="upTimeLine">更新时间线</div>
                    </div> -->
                </div>
                <!-- <el-dropdown>
                    <span class="el-dropdown-link">
                        合适<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>合适</el-dropdown-item>
                        <el-dropdown-item>19:6</el-dropdown-item>
                        <el-dropdown-item>6:19</el-dropdown-item>
                        <el-dropdown-item>1:1</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        720P<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>720P</el-dropdown-item>
                        <el-dropdown-item>360P</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        抖音模式<i
                            class="el-icon-arrow-down el-icon--right"
                        ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>抖音模式</el-dropdown-item>
                        <el-dropdown-item>西瓜模式</el-dropdown-item>
                        <el-dropdown-item>快手预览</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
        </div>
    </div>
</template>

<script>
import loadJs from "@/utils/load";
import Bus from "@/utils/eventBus";
import { getPreview, gitTimeLine } from "@/api/engi.js";
import VueDragResize from "vue-drag-resize";
export default {
    name: "play",
    components: { VueDragResize },
    props: ["playLine"],
    data() {
        return {
            imgUrl: "",
            contenteditable: true,
            showPlay: true,
            isLoading: false,
            player: "",
            paly_area: {
                w: 0,
                h: 0,
            },
            getPlayTimer: "",
            currTime: 0, //播放时长
            duration: 0, //预览时长

            //可编辑轨道
            editTrack: {
                txts: [],
                images: [],
                filters: [],
            },
            moveAdd: {
                radom: 0,
                isShow: false,
                width: 0,
                height: 0,
                x: 0,
                y: 0,
            },
            // 选中的轨道
            selectTrack: {},

            // new
            previewInfo: "", //预览数据
            timeLine: "",
            // 图片轨道
            imgClips: [],
        };
    },
    watch: {
        playLine(data) {
            // console.log(data);
            if (data) {
                // 切换了预览数据
                let previewInfo = JSON.parse(data);
                let status = 0; //0 初始化时间线 1更新时间线
                if (previewInfo.id === this.previewInfo.id) {
                    // 更新时间线
                    status = 1;
                    console.log("更新时间线");
                }
                this.previewInfo = previewInfo;

                let videoTracks = this.previewInfo.timeLine.VideoTracks;
                let imgTrack = videoTracks.find(
                    (item) => item.Type === "Image"
                );
                // console.log(videoTracks);
                if (imgTrack) {
                    this.imgClips = imgTrack.VideoTrackClips;
                }

                this.ali_preview(status);
            } else {
                // 选中列表为空，销毁预览组件
                this.previewInfo = {};
                this.player.destroy();
            }
        },
        currTime: function (data) {
            // 时长发生变化同步到编辑组件
            Bus.$emit("playcurren", data);
            // this.$parent.playTimer(data);
        },
    },
    created() {
        loadJs(
            "https://g.alicdn.com/thor-server/video-editing-websdk/4.11.0/player.js"
        ).then((res) => {
            // if (this.combination) {
            //     console.log(this.combination);
            //     this.timeLine = this.combination;
            //     this.ali_preview();
            // }
        });
    },
    beforeDestroy() {
        this.pause();
        this.player.destroy();
        this.player = undefined;
        Bus.$off();
    },
    mounted() {
        let play_box = this.$refs.play_box;
        let pw = play_box.clientWidth;
        let ph = play_box.clientHeight;

        Bus.$on("edits", (res) => {
            this.editTrack = res;
        });
        Bus.$on("upTxt", (res) => {
            console.log(res);
            // this.editTrack.txts = res;
            let videoTracks = this.timeLine.timeLine.VideoTracks;
            console.log(videoTracks);
            // videoTracks.forEach(list => {

            // });
            let subTitleTracks = videoTracks.filter(
                (item) => item.Type === "Subtitle"
            );
            // 遍历字幕轨道
            subTitleTracks.forEach((list) => {
                // 遍历字幕
                list.VideoTrackClips.forEach((item) => {
                    // 匹配修改字幕
                    let edId = res.findIndex((ed) => ed.Id === item.Id);
                    console.log(edId);
                    if (edId != -1) {
                        // 匹配成功
                        console.log("匹配成功");
                        item = res[edId];
                        console.log(res[edId]);
                    }
                });
            });
            // console.log(subTitleTracks);
            // console.log(subTitle);
            // res.forEach((item) => {
            //     // console.log(item);
            //     let index = videoTracks.findIndex(
            //         (list) => list.groupIndex === item.groupIndex
            //     );
            //     console.log(index);
            //     // videoTracks[index] = item;
            //     // console.log(index);
            //     // console.log(videoTracks[index]);
            //     // console.log(item);
            // });
            // // console.log(this.timeLine);
            // // Bus.$emit("upLine", this.timeLine);
            // this.ali_preview();
        });
        // Bus.$on("addTxt", (res) => {
        //     console.log(res);
        //     // let videoTracks = this.timeLine.timeLine.VideoTracks;
        //     // videoTracks.push(res);
        //     // Bus.$emit("upLine", this.timeLine);
        //     // this.ali_preview();
        // });
        // 修改时间线
        Bus.$on("editLine", (res) => {
            this.timeLine = res;
            this.ali_preview();
        });
        // Bus.$on("ADD_IMG", (res) => {
        //     this.addImg(res);
        // });
        Bus.$on("DEL_IMG", (res) => {
            this.delImg(res);
        });
        // Bus.$on("ADD_FILTER", (res) => {
        //     this.setFilter(res);
        // });
        // 设置播放点
        Bus.$on("setPlayTime", (res) => {
            console.log(res);
            this.setPlayTime(res);
        });

        Bus.$on("ImgTimeSet", (res) => {
            let tracks = this.previewInfo.timeLine.VideoTracks;
            let imgTrack = tracks.find((list) => list.Type === "Image");
            let upIndex = imgTrack.VideoTrackClips.findIndex(
                (list) => list.Id === res.id
            );
            if (upIndex != -1) {
                imgTrack.VideoTrackClips[upIndex].TimelineIn = res.times[0];
                imgTrack.VideoTrackClips[upIndex].TimelineOut = res.times[1];
            }
            this.player.timeline = this.previewInfo.timeLine;
            // 同步其他组件
            this.$parent.upImg(imgTrack.VideoTrackClips[upIndex]);

            // console.log(this.previewInfo.timeLine);
            // console.log(imgTrack);
            // console.log(tracks);
        });
    },

    methods: {
        upTimeLine() {
            let timeline = this.previewInfo.timeLine;
            timeline.VideoTracks[1].VideoTrackClips[0].Color = "#ff0000";
            timeline.VideoTracks[1].VideoTrackClips[0].Y = 0.1;
            this.player.timeline = {};
            this.player.timeline = timeline;
        },
        setFilter(val) {
            let timeline = this.timeLine.timeLine;
            let index = timeline.VideoTracks.findIndex(
                (item) => item.Type === "Effect"
            );
            if (index != -1) {
                // 已存在滤镜，修改
                console.log("存在滤镜");
                timeline.VideoTracks[index].VideoTrackClips[0].SubType = val;
                timeline.VideoTracks[
                    index
                ].VideoTrackClips[0].Name = `filters.${val}`;
                timeline.VideoTracks[
                    index
                ].VideoTrackClips[0].ExtParams = `effect=${val}`;
            } else {
                let trackClip = {
                    Id: Math.ceil(Math.random() * 10000000),
                    Type: "Effect",
                    Visible: true,
                    Count: 1,
                    VideoTrackClips: [
                        {
                            Type: "Filter",
                            Id: Math.ceil(Math.random() * 10000000),
                            TrackId: 3,
                            Index: 138,
                            SubType: "pfc", //滤镜类型
                            Name: "filters.pfc",
                            CoverUrl:
                                "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg",
                            Properties: {},
                            Category: "film",
                            EffectType: "filter",
                            EffectConfig: {
                                Image: "https://ice-pub-media.myalicdn.com/websdk/lut/32/PFC.png",
                            },
                            TimelineIn: 0,
                            TimelineOut: 100,
                            Duration: 100,
                            X: 0,
                            Y: 0,
                            Width: 0,
                            Height: 0,
                            CanScale: false,
                            ExtParams: "effect=pfc",
                        },
                    ],
                };
                trackClip.VideoTrackClips[0].SubType = val;
                trackClip.VideoTrackClips[0].Name = `filters.${val}`;
                trackClip.VideoTrackClips[0].ExtParams = `effect=${val}`;
                timeline.VideoTracks.push(trackClip);
            }
            Bus.$emit("upLine", this.timeLine);
            this.ali_preview();
        },
        // 给TimeLine添加贴纸
        async addImg(data) {
            let trackClip = {
                Id: 1,
                Type: "Image",
                Count: 1,
                VideoTrackClips: [
                    {
                        Type: "Image",
                        Id: "",
                        TrackId: 1,
                        // MediaId: "66864e82a7079a2d9625b9f618ecdc18",
                        Src: "",
                        TimelineIn: 0,
                        TimelineOut: 5,
                        Duration: 15,
                        X: 0.5,
                        Y: 0.5,
                        Width: 0.185,
                        Height: 0.104,
                        BusinessType: "Sticker",
                    },
                ],
            };
            trackClip.Id = Math.ceil(Math.random() * 100000000);
            trackClip.VideoTrackClips[0].Id = Math.ceil(
                Math.random() * 100000000
            );
            trackClip.VideoTrackClips[0].Src = data.url;
            trackClip.VideoTrackClips[0].TimelineIn = data.timeIn;
            trackClip.VideoTrackClips[0].TimelineOut = data.timeIn + 5;

            this.timeLine.timeLine.VideoTracks.push(trackClip);
            // 保存时间线
            let json = {
                previewId: this.timeLine.id,
                timeLine: JSON.stringify(this.timeLine.timeLine),
            };
            let res = await gitTimeLine(json);
            if (res.code == 200) {
                // 保存成功
                Bus.$emit("upLine", this.timeLine);
                this.showResize(trackClip);
                this.ali_preview();
            } else {
                this.$message.error("添加贴纸失败");
            }
        },
        async delImg(data) {
            let id = data.Id;
            console.log(data);
            let timeLine = this.timeLine.timeLine;
            let index = timeLine.VideoTracks.findIndex(
                (item) => item.Id === id
            );
            timeLine.VideoTracks.splice(index, 1);

            let json = {
                previewId: this.timeLine.id,
                timeLine: JSON.stringify(this.timeLine.timeLine),
            };
            let res = await gitTimeLine(json);
            if (res.code == 200) {
                // 保存成功
                // Bus.$emit("upLine", this.timeLine);
                this.moveAdd = {
                    isShow: false,
                    radom: 0,
                    width: 0,
                    height: 0,
                    x: 0,
                    y: 0,
                };

                this.ali_preview();
            } else {
                this.$message.error("删除贴纸失败");
            }
        },
        // 选中图片
        showResize(info) {
            // info图片轨道信息
            this.selectTrack = info;
            let data = info;
            let { w, h } = this.paly_area;
            let json = {
                id: info.Id,
                isShow: true,
                radom: Math.random(),
                width: w * data.Width,
                height: h * data.Height,
                x: w * data.X,
                y: h * data.Y,
            };
            // 记录当前图片位置
            this.moveAdd = json;
        },
        deactivated() {
            //点击移动框外
            this.moveAdd.isShow = false;
        },
        setPlayTime(val) {
            console.log(val);
            this.player.currentTime = val;
            this.currTime = val;
        },
        play() {
            try {
                this.player.play();
            } catch (error) {
                return;
            }

            let _this = this;
            this.getPlayTimer = setInterval(function () {
                let currTime = _this.player.currentTime;
                _this.currTime = currTime.toFixed(2) * 1;
                if (currTime >= _this.duration) {
                    clearInterval(_this.getPlayTimer);
                    _this.getPlayTimer = null;
                    _this.currTime = 0;
                    _this.player.currentTime = 0;
                }
            }, 100);
        },
        pause() {
            let _this = this;
            _this.player.pause();
            clearInterval(_this.getPlayTimer);
            _this.getPlayTimer = null;
        },
        hideResize() {
            // console.log(2);
            // let json = {
            //     isShow: false,
            //     radom: 0,
            //     width: 0,
            //     height: 0,
            //     x: 0,
            //     y: 0,
            // };
            // this.moveAdd.isShow = false;
            // this.moveAdd.radom = 0;
            // this.moveAdd.width = 0;
            // this.moveAdd.height = 0;
            // this.moveAdd.x = 0;
            // this.moveAdd.y = 0;
        },
        // 图片移动停止
        dragstop(sizes) {
            // 计算位置，宽高
            let y, x, width, height;
            let { w, h } = this.paly_area;
            y = sizes.top / h;
            height = sizes.height / h;
            x = sizes.left / w;
            width = sizes.width / w;
            this.selectTrack.X = x;
            this.selectTrack.Y = y;
            this.selectTrack.Width = width;
            this.selectTrack.Height = height;
            // 更新预览timeLine
            // this.moveAdd.isShow = false;
            // console.log(this.selectTrack);
            this.player.timeline = this.previewInfo.timeLine;

            // 同步其他组件
            this.$parent.upImg(this.selectTrack);
        },
        resizestop(sizes) {
            let parentW = Math.ceil(this.paly_area.w);
            let parentH = Math.ceil(this.paly_area.h);
            let add_size = {
                id: this.selectTrack.Id,
                width: sizes.width / parentW,
                height: sizes.height / parentH,
                x: sizes.left / parentW,
                y: sizes.top / parentH,
            };
            // let index;
            // this.$store.commit("upGroupVideo", add_size);
            // Bus.$emit("upImg", add_size);
            console.log(add_size);
            // let setInfo = {
            //     Type: "Image",
            //     VideoTrackClips: [
            //         {
            //             Id: Math.random(),
            //             Type: "Image",
            //             TrackId: 1,
            //             Src: this.imgUrl.url, //图片oss地址
            //             TimelineIn: this.player.currentTime, //进场时间
            //             TimelineOut: this.player.currentTime + 5, //出场时间
            //             Duration: 15, //
            //             // X: 0.1, //x缩放
            //             // Y: 0.1,
            //             // Width: 0.185,
            //             // Height: 0.104,
            //             ...add_size,
            //             BusinessType: "Sticker",
            //         },
            //     ],
            // };
            // let timeLine;
            // try {
            //     timeLine = JSON.parse(this.timeLine);
            //     timeLine.VideoTracks.push(setInfo);
            //     // this.lineTes2.VideoTracks.push(setInfo);
            //     console.log(timeLine);
            //     this.ali_preview(timeLine);
            // } catch (error) {
            //     console.log(timeLine);
            // }
        },
        resize(newRect) {
            // console.log("拖拽中");
            // let { w, h } = this.paly_area;
            // console.log(w, h);
            // console.log(newRect);
            // this.vw = newRect.width;
            // this.vh = newRect.height;
            // this.top = newRect.top;
            // this.left = newRect.left;
        },
        // 预览
        ali_preview(status) {
            let timeline = this.previewInfo.timeLine;
            if (status) {
                this.player.timeline = {};
                this.player.timeline = timeline;
                return;
            }
            if (!timeline) return;
            // 设置容器大小
            // 获取分辨率

            let { x, y } = timeline.FECanvas || { x: 1080, y: 1920 };
            let bili = timeline.AspectRatio.split(":");
            let video_dom = this.$refs.video_box;
            // console.log(video_dom);
            if (bili[0] * 1 > bili[1] * 1) {
                let zanbi = bili[1] / bili[0];
                let w = video_dom.clientWidth;
                console.log("w", w);
                this.paly_area.w = w;
                this.paly_area.h = w * zanbi;
                // console.log(zanbi);
            } else {
                // console.log(video_dom);
                let h = video_dom.clientHeight;
                let zanbi = bili[0] / bili[1];
                let w = h * zanbi;

                this.paly_area.w = w;
                this.paly_area.h = h;
            }
            if (this.player) {
                this.player.timeline = { VideoTracks: [] };
                this.player.destroy();
                this.player = undefined;
            }
            this.player = new window.AliyunTimelinePlayer({
                container: document.querySelector("#player-wrapper"),
                timeline,
                aspectRatio: timeline.AspectRatio,
                controls: false,
                minWidth: 1,
                getMediaInfo: async (mediaId, mediaType) => {
                    let res = await getPreview({
                        MediaId: mediaId,
                        Action: "GetMediaInfo",
                    });
                    return res.MediaInfo.FileInfoList[0].FileBasicInfo.FileUrl;
                },
            });
            let duration = this.player.duration;
            Bus.$emit("playtime", duration);
            this.duration = duration;
            this.$emit("play_size", this.paly_area);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-slider__runway {
    height: 4px;
    margin: 12px 0;
}
/deep/ .el-slider__button {
    width: 12px;
    height: 12px;
    border-width: 1px;
}
/deep/ .el-slider__button-wrapper {
    height: 22px;
    width: 22px;
    top: -10px;
}
</style>

<style lang="less" scoped>
.play {
    height: 100%;
    display: flex;
    flex-direction: column;
    .play-box {
        flex: 1;
        position: relative;
    }
    .play-control {
        flex: 0 0 46px;
        position: relative;
        .schedule {
            position: absolute;
            width: 100%;
            left: 0;
            top: -13px;
            z-index: 99;
        }
        .control_row {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .lr {
                width: 200px;
            }
        }
    }
    .video {
        height: 100%;
    }
}
</style>

<style scoped>
@import url("../../../assets/ali_player.css");
</style>