export default {
    SimSun: {
        label: "宋体",
        value: "SimSun",
    },
    fangsong: {
        label: "仿宋",
        value: "fangsong",
    },
    KaiTi: {
        label: "楷体",
        value: "KaiTi",
    },
    HappyZcool: {
        label: "站酷快乐体",
        value: "HappyZcool-2016",
    },
    wenyiti: {
        label: "站酷文艺体",
        value: "zcool-wenyiti",
    },
    gaoduanhei: {
        label: "站酷高端黑体",
        value: "zcool-gaoduanhei",
    },
    MicroHei: {
        label: "文泉驿微米黑",
        value: "wqy-microhei",
    },
    ZenHeiMono: {
        label: "文泉驿等宽正黑",
        value: "wqy-zenhei-mono",
    },
    ZenHeiSharp: {
        label: "文泉驿点阵正黑",
        value: "wqy-zenhei-sharp",
    },
    SiYuanHeiti: {
        label: "思源黑体",
        value: "siyuan-heiti",
    },
    SiYuanSimSun: {
        label: "思源宋体",
        value: "siyuan-songti",
    },
}
// export default [
//     {
//         label: "宋体",
//         value: "SimSun",
//         scale: 1.36,
//         topOffset: 0.7,
//         letterSpacing: '0.4px'
//     },
//     {
//         label: "仿宋",
//         value: "fangsong",
//     },
//     {
//         label: "楷体",
//         value: "KaiTi",
//     },
//     {
//         label: "思源黑体",
//         value: "siyuan-heiti",
//     },
// ]