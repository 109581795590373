<style lang="less" scoped>
.tabs-hd {
    display: flex;
    background-color: #f5f6f8;
    padding: 3px;
    font-size: 12px;
    border-radius: 2px;
    margin: 0 !important;
    li {
        padding: 2px 8px;
        color: #334681;
        border-radius: 2px;
        cursor: pointer;
    }
    .active {
        background-color: #fff;
        color: #349ffd;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
    .iconfont {
        font-size: 12px;
        margin-right: 2px;
    }
}
</style>
<template>
    <div class="tabs-box">
        <ul class="tabs-hd">
            <li
                :class="index == value ? 'active' : ''"
                v-for="(item, index) in data"
                :key="index"
                @click="change(index)"
            >
                <i v-if="item.icon" :class="item.icon"></i>{{ item.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "tabs",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: Number,
        data: Array,
    },
    mounted() {
        // console.log(this.data);
    },
    methods: {
        change(index) {
            // this.value = index;
            // console.log(this.$event);
            this.$emit("change", index);
        },
    },
};
</script>