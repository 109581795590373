export default [
    {
        name: "无",
        value: "",
        img: require("@/assets/images/fission/gif/wu.png"),
        select: true,
    },
    {
        name: "复古",
        value: "m1",
        img: require("@/assets/images/gif/filter/pf/m1.webp"),
        select: false,
    },
    {
        name: "灰调",
        value: "m2",
        img: require("@/assets/images/gif/filter/pf/m2.webp"),
        select: false,
    },
    {
        name: "青阶",
        value: "m3",
        img: require("@/assets/images/gif/filter/pf/m3.webp"),
        select: false,
    },
    {
        name: "蓝调",
        value: "m4",
        img: require("@/assets/images/gif/filter/pf/m4.webp"),
        select: false,
    },
    {
        name: "暗红",
        value: "m5",
        img: require("@/assets/images/gif/filter/pf/m5.webp"),
        select: false,
    },
    {
        name: "暗淡",
        value: "m6",
        img: require("@/assets/images/gif/filter/pf/m6.webp"),
        select: false,
    },
    {
        name: "灰橙",
        value: "m7",
        img: require("@/assets/images/gif/filter/pf/m7.webp"),
        select: false,
    },
    {
        name: "通透",
        value: "m8",
        img: require("@/assets/images/gif/filter/pf/m8.webp"),
        select: false,
    },
    {
        name: "高调",
        value: "pf1",
        img: require("@/assets/images/gif/filter/pf/pf1.webp"),
        select: false,
    },
    {
        name: "富士",
        value: "pf2",
        img: require("@/assets/images/gif/filter/pf/pf2.webp"),
        select: false,
    },
    {
        name: "暖色",
        value: "pf3",
        img: require("@/assets/images/gif/filter/pf/pf3.webp"),
        select: false,
    },
    {
        name: "柯达",
        value: "pf4",
        img: require("@/assets/images/gif/filter/pf/pf4.webp"),
        select: false,
    },
    {
        name: "清透",
        value: "pi1",
        img: require("@/assets/images/gif/filter/pi/pi1.webp"),
        select: false,
    },
    {
        name: "暮晚",
        value: "pi2",
        img: require("@/assets/images/gif/filter/pi/pi2.webp"),
        select: false,
    },
    {
        name: "秋色",
        value: "pi3",
        img: require("@/assets/images/gif/filter/pi/pi3.webp"),
        select: false,
    },
    {
        name: "暗调",
        value: "pi4",
        img: require("@/assets/images/gif/filter/pi/pi4.webp"),
        select: false,
    },
]