<style lang="less">
.area {
    background-color: #fff;
    height: 100%;
}
</style>
<template>
    <div class="combination">
        <el-drawer
            title="选择存放位置"
            :visible.sync="showMenu"
            direction="ltr"
        >
            <div class="tree-box">
                <MENU :material="'mev'" :type="1" @senData="save_menu" />
            </div>
        </el-drawer>

        <div class="left">
            <LIST
                class="list"
                @changeList="changeList"
                @payPreview="payPreview"
                @change_all="change_all"
                ref="list_dom"
                :list="previewList"
                :select_count="selectList.length"
                :play_id="pay_id"
                :isLoading="preview_loading"
            />
            <div class="page">
                <el-pagination
                    :page-size.sync="where.pageSize"
                    :current-page.sync="where.currentPage"
                    @current-change="getVideoList"
                    background
                    small
                    layout="prev, pager, next"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <div class="center">
            <PLAY
                :playLine="playLine"
                ref="play_dom"
                @editImg="editImg"
                @play_size="play_size"
            />
        </div>
        <div class="right">
            <EDIT
                :edits="edits"
                @addTxt="addTxt"
                @delTxt="delTxt"
                @addFilter="addFilter"
                :combination="selected"
                :selects="selectList"
                @getCaptions="getCaptions"
                @delImg="delImg"
                @upImg="upImg"
                @save_group="save_group"
            />
        </div>
    </div>
</template>

<script>
import LIST from "@/components/edit/combination/list";
import PLAY from "@/components/edit/combination/play";
import EDIT from "@/components/edit/combination/edit";
import MENU from "@/components/work/ku_menu";
import fontConfig from "@/assets/js/fontConfig";
import Bus from "@/utils/eventBus.js";
import {
    getPreviewList,
    generateCaptions,
    getTimeLine,
    gitTimeLine,
    generate,
} from "@/api/engi";
export default {
    name: "combination",
    components: { LIST, PLAY, MENU, EDIT },
    data() {
        return {
            // 字体配置
            fontConfig,
            // 选中列表
            selectList: [],
            // 预览时间线
            selected: "",
            play_area_size: "",
            // 预览区域大小
            showMenu: false,

            // 新 start
            // 获取分页loading状态
            preview_loading: false,
            // 编辑内容
            edits: {
                txts: [],
                images: [],
                filters: [],
            },
            previewList: [], //预览列表
            selectListt: [], //选择列表

            total: 0, //预览列表总页数
            //列表查询条件
            where: {
                projectId: this.$store.state.fiss_id,
                pageSize: 20,
                currentPage: 1,
                status: 0,
            },
            playLine: "", //预览时间线
            pay_id: "",
            // 新 end
        };
    },
    watch: {
        selectList() {
            // 监听选中列表的变化
            this.resetElement();
        },
    },
    mounted() {
        // this.$on("changeList", (list) => {
        //     console.log("changelist");
        //     this.selectList = list;
        // });

        // edit组件通知
        // 通知字幕识别
        // this.$on("getCaptions", (res) => {
        //     // this.getCaptions();
        // });
        // this.$on("save_group", this.save_group);
        // this.$on("play_size", (res) => {
        //     console.log(res);
        // });
        Bus.$on("save", this.gitTimeLine);
    },
    created() {
        // 查询预览列表
        this.getVideoList();
    },
    beforeDestroy() {
        Bus.$off("save");
    },
    methods: {
        play_size(res) {
            let play_size = {
                Width: res.w,
                Height: res.h,
            };
            this.play_area_size = JSON.stringify(play_size);
        },
        async save_menu(res) {
            if (!this.selectList.length) {
                return this.$message.error("请选择合成视频");
            }
            let loading = this.$loading({
                lock: true,
                text: "正在提交合成任务",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let ids = [];
            this.selectList.forEach((item) => {
                ids.push(item.id);
            });
            // 目录ID
            let mid = res.id;

            let generate_data = {
                // previewIdList: JSON.stringify(ids),toString
                previewIdList: ids.toString(),
                dictoryId: mid,
            };

            let generate_res = await generate(generate_data);
            loading.close();
            if (generate_res.code == 200) {
                this.showMenu = false;
                this.$message.success("提交任务成功");
            } else {
                this.$message.error("提交任务失败");
            }
        },
        save_group() {
            this.showMenu = true;
            console.log("保存到素材库");
        },
        editImg(img) {
            // console.log("img编辑了");
            this.$refs.list_dom.upImg(img, "edit");
        },

        // 新 start

        // 识别字幕
        async getCaptions() {
            let selectList = this.selectList;
            if (!selectList.length) {
                return this.$message.error("请先选择要识别的组合");
            }
            let ids = [];
            selectList.forEach((item) => {
                ids.push(item.id);
            });
            let captions_data = {
                previewIdList: ids.toString(),
            };
            // 识别字幕，更新时间线
            let captions = await generateCaptions(captions_data);
            // console.log(captions.data);
            if (captions.code == 200) {
                captions.data.forEach((item) => {
                    // console.log(item);
                    let txts = JSON.parse(item.timeLine);
                    console.log(txts);
                    let previewId = item.videoPreviewId;
                    // 查找字幕所在列表的索引
                    let selectIndex = this.selectList.findIndex(
                        (list) => list.id === previewId
                    );

                    let timeline = selectList[selectIndex].timeLine;

                    this.$set(selectList[selectIndex], "renew", true);
                    // 查找是否已有字幕轨道
                    var index = timeline.VideoTracks.findIndex(
                        (clip) => clip.clip_type == "txt"
                    );

                    if (index == -1) {
                        // 没有手动添加过字幕轨道
                        let clip_txt = {
                            Id: Math.floor(Math.random() * 1000000),
                            clip_type: "txt",
                            VideoTrackClips: [],
                            Type: "Subtitle",
                        };
                        let num = timeline.VideoTracks.push(clip_txt);
                        index = num - 1;
                    }

                    //添加字幕
                    // console.log(txts);
                    txts.map((item) => {
                        //字幕轨道添加ID
                        item.Id = `${item.audioId}${item.groupIndex}${item.Id}`;
                        // 背景色
                        item.BackColour = "";
                        // 投影大小
                        item.Shadow = 0;
                        // 字体边线大小
                        item.Outline = 0;
                        // 字体边线颜色
                        item.OutlineColour = "";
                    });
                    timeline.VideoTracks[index].VideoTrackClips = txts;
                });
                // 更新预览时间线
                this.setPreviewList();
                this.resetElement();
            } else {
                this.$message.error("识别失败");
            }
        },
        // 获取预览数据
        getVideoList() {
            this.selectList = [];
            // console.log("切换分页");
            this.preview_loading = true;
            let where = this.where;
            where.projectId = this.$store.state.fiss_id;
            // console.log(where);
            getPreviewList(where).then((res) => {
                this.previewList = res.data.list;
                this.previewList.map((item) => {
                    item.isUp = false;
                    if (item.timeLine) {
                        item.timeLine = JSON.parse(item.timeLine);
                    }
                });
                this.total = res.data.pagination.total;
                // 清空预览时间线
                this.playLine = null;
                this.preview_loading = false;
            });
        },
        // 选中视频
        async changeList(res) {
            const list_dom = this.$refs.list_dom;
            const isChecks = list_dom.isChecks; //0单选，1多选
            // console.log();
            let previewIndex = res.index;
            let item = this.previewList[previewIndex];
            let isCheck = item.isCheck;
            if (isCheck) {
                // 取消选中
                this.$set(item, "isCheck", false);
                let removeIndex = this.selectList.findIndex(
                    (select) => select.id === res.info.id
                );
                this.selectList.splice(removeIndex, 1);
            } else {
                // 选中
                this.$set(item, "isCheck", true);
                if (isChecks == 1) {
                    // 多选
                    this.selectList.unshift(res.info);
                } else {
                    // 单选
                    this.selectList.forEach((list) => {
                        this.$set(list, "isCheck", false);
                    });
                    this.selectList = [];
                    this.selectList.push(item);
                }
                // if (!item.timeLine) {
                //     // 获取时间线
                //     let line = await this.getTimeLine(item, previewIndex);
                //     // console.log(item);
                //     if (!line) {
                //         return false;
                //     }
                // }
            }
            this.setPreviewList(this.selectList[0]);
        },
        change_all(val) {
            console.log(val);
            this.selectList = [];
            if (val) {
                // 全选
                let selectList = [];
                this.previewList.map((item) => {
                    item.isCheck = true;
                    selectList.unshift(item);
                });
                this.selectList = selectList;
            } else {
                this.previewList.map((item) => {
                    this.$set(item, "isCheck", false);
                });
            }
            this.setPreviewList(this.selectList[0]);
        },
        // 获取时间线
        async getTimeLine(preview) {
            const loading = this.$loading({
                lock: true,
                text: "正在生成预览数据",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let timeLinReq = {
                projectId: this.$store.state.fiss_id,
                previewIdList: preview.id,
            };
            let line = await getTimeLine(timeLinReq);
            loading.close();
            if (line.code === 200) {
                // 添加时间线
                this.$set(
                    preview,
                    "timeLine",
                    JSON.parse(line.data[0].timeLine)
                );
                return true;
            } else {
                this.$message.error("生成预览视频数据失败，请联系管理员");
                return false;
            }
        },
        // 切换预览
        async payPreview(list) {
            let previewItem = this.previewList[list.index];
            // 判断是否已生成时间线
            if (list.info.timeLine) {
                // 存在，判断是否已在选择列表
                let checkIndex = this.selectList.findIndex(
                    (item) => item.id === previewItem.id
                );

                if (checkIndex != -1) {
                    // 选择列表已存在，删除
                    this.selectList.splice(checkIndex, 1);
                }
            } else {
                // 不存在，获取时间线
                let line = await this.getTimeLine(previewItem);
                if (!line) return false;

                this.$set(previewItem, "isCheck", true);
            }
            this.selectList.unshift(previewItem);
            this.setPreviewList(this.selectList[0]);
        },
        // 设置预览数据
        setPreviewList() {
            let selectList = this.selectList;
            if (selectList.length) {
                let payInfo = selectList[0];
                this.pay_id = payInfo.id;
                this.playLine = JSON.stringify(payInfo);
            } else {
                this.playLine = null;
            }
        },
        // 添加字幕
        addTxt(res) {
            this.selectList.forEach((item) => {
                // console.log(item.timeLine.VideoTracks);
                var index = item.timeLine.VideoTracks.findIndex(
                    (clip) => clip.clip_type == "txt"
                );
                if (index == -1) {
                    // 没有手动添加过字幕
                    let clip_txt = {
                        Id: Math.floor(Math.random() * 1000000),
                        clip_type: "txt",
                        VideoTrackClips: [],
                        Type: "Subtitle",
                    };
                    let num = item.timeLine.VideoTracks.push(clip_txt);
                    // console.log(num);
                    index = num - 1;
                }
                // console.log(item.timeLine.VideoTracks, index);
                item.timeLine.VideoTracks[index].VideoTrackClips.push(res);

                this.setUpState(item, true);
            });
            this.setPreviewList();
        },
        // 删除字幕
        delTxt(res) {
            let clipsId = res.Id;
            // console.log(img);
            let selectList = this.selectList;
            selectList.forEach((item) => {
                let tracks = item.timeLine.VideoTracks;
                let txt_clips = tracks.find((txts) => txts.Type === "Subtitle");

                let clip_index = txt_clips.VideoTrackClips.findIndex(
                    (txts) => txts.Id === clipsId
                );
                if (clip_index > -1) {
                    txt_clips.VideoTrackClips.splice(clip_index, 1);
                    // console.log(selectList);
                    this.setUpState(item);
                    this.setPreviewList();
                }
            });
        },
        // 添加滤镜
        addFilter(val) {
            this.selectList.forEach((item) => {
                let filterIndex = item.timeLine.VideoTracks.findIndex(
                    (list) => list.Type == "Effect"
                );
                if (filterIndex === -1) {
                    // 不存在滤镜
                    let trackClip = {
                        Id: Math.ceil(Math.random() * 10000000),
                        Type: "Effect",
                        Visible: true,
                        Count: 1,
                        VideoTrackClips: [
                            {
                                Type: "Filter",
                                Id: Math.ceil(Math.random() * 10000000),
                                TrackId: 3,
                                Index: 138,
                                SubType: "pfc", //滤镜类型
                                Name: "filters.pfc",
                                CoverUrl:
                                    "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg",
                                Properties: {},
                                Category: "film",
                                EffectType: "filter",
                                EffectConfig: {
                                    Image: "https://ice-pub-media.myalicdn.com/websdk/lut/32/PFC.png",
                                },
                                TimelineIn: 0,
                                TimelineOut: 100,
                                Duration: 100,
                                X: 0,
                                Y: 0,
                                Width: 0,
                                Height: 0,
                                CanScale: false,
                                ExtParams: "effect=pfc",
                            },
                        ],
                    };
                    trackClip.VideoTrackClips[0].SubType = val;
                    trackClip.VideoTrackClips[0].Name = `filters.${val}`;
                    trackClip.VideoTrackClips[0].ExtParams = `effect=${val}`;
                    item.timeLine.VideoTracks.push(trackClip);
                } else {
                    // 不设置滤镜
                    if (val == "") {
                        console.log(item.timeLine.VideoTracks.VideoTrackClips);
                        item.timeLine.VideoTracks.splice(filterIndex, 1);
                    } else {
                        // let
                        // 存在滤镜轨道

                        item.timeLine.VideoTracks[
                            filterIndex
                        ].VideoTrackClips[0].SubType = val;
                        item.timeLine.VideoTracks[
                            filterIndex
                        ].VideoTrackClips[0].Name = `filters.${val}`;
                        item.timeLine.VideoTracks[
                            filterIndex
                        ].VideoTrackClips[0].ExtParams = `effect=${val}`;
                    }
                }
                this.setPreviewList();
                this.setUpState(item, true);
                // let json = {
                //     previewId: item.id,
                //     timeLine: item.timeLine,
                // };
                // saveLineData.push(json);
            });
        },
        // 添加贴纸
        addImg(image) {
            this.selectList.forEach((item) => {
                let imgIndex = item.timeLine.VideoTracks.findIndex(
                    (list) => list.Type === "Image"
                );
                if (imgIndex === -1) {
                    let trackId =
                        Date.parse(new Date()) +
                        Math.floor(Math.random() * 1000);
                    let trackClip = {
                        Id: trackId,
                        Type: "Image",
                        Count: 1,
                        VideoTrackClips: [],
                    };
                    let img_clip = image;
                    trackClip.VideoTrackClips.push(img_clip);
                    // 添加img轨道
                    item.timeLine.VideoTracks.push(trackClip);
                } else {
                    // 在img轨道添加图片
                    let img_clip = image;
                    item.timeLine.VideoTracks[imgIndex].VideoTrackClips.push(
                        img_clip
                    );
                }

                this.setUpState(item, true);
            });
            this.resetElement();
            this.setPreviewList();
        },
        // 修改贴纸
        upImg(res) {
            let clip_id = res.Id;

            let selectList = this.selectList;
            selectList.forEach((item) => {
                let tracks = item.timeLine.VideoTracks;
                let img_clips = tracks.find((img) => img.Type === "Image");

                let clip_index = img_clips.VideoTrackClips.findIndex(
                    (img) => img.Id === clip_id
                );
                img_clips.VideoTrackClips[clip_index] = res;
                // img_clip = res;
                // console.log(img_clip);
                this.setUpState(item, true);
            });
            // console.log(selectList);
        },

        delImg(img) {
            let clipsId = img.Id;
            // console.log(img);
            let selectList = this.selectList;
            selectList.forEach((item) => {
                let tracks = item.timeLine.VideoTracks;
                let img_clips = tracks.find((img) => img.Type === "Image");

                let clip_index = img_clips.VideoTrackClips.findIndex(
                    (img) => img.Id === clipsId
                );
                if (clip_index > -1) {
                    img_clips.VideoTrackClips.splice(clip_index, 1);
                    // console.log(selectList);
                    this.setUpState(item);
                    this.setPreviewList();
                }
            });
        },
        // 设置列表更新状态
        setUpState(item, statue) {
            this.$set(item, "renew", true);
        },
        // 保存到服务器更新的数组保存到服务器
        async gitTimeLine() {
            console.log("保存到服务器");
            var previewList = this.previewList;
            var upData = [];
            previewList.forEach((item) => {
                console.log(this.$refs.play_dom.paly_area);
                let previewArea = this.$refs.play_dom.paly_area;
                // console.log(item.timeLine.FECanvas.);
                // item.timeLine.FECanvas.Width = previewArea.w;
                // item.timeLine.FECanvas.Height = previewArea.h;
                // 9:16 固定是这个值,width:253,height:450，16:9的值是width:800,height:450
                item.timeLine.FECanvas.Width = 253;
                item.timeLine.FECanvas.Height = 450;
                // console.log(item);
                if (item.renew) {
                    item.ti;
                    let upList = {
                        previewId: "",
                        timeLine: "",
                    };
                    upList.previewId = item.id;
                    upList.timeLine = item.timeLine;
                    upData.push(upList);
                }
            });
            if (!upData.length) {
                this.$message.error("未检测到修改的视频组合");
            } else {
                let data = { timeLineArrayString: JSON.stringify(upData) };
                Bus.$emit("setLoading", true);
                let res = await gitTimeLine(data);

                Bus.$emit("setLoading", false);
                if (res.code == 200) {
                    previewList.forEach((item) => {
                        if (item.renew) {
                            item.renew = false;
                        }
                    });
                    this.$message.success("保存成功");
                } else {
                    this.$message.error("保存失败");
                }
            }
        },
        // 提取选中列表的 图片，文字
        resetElement() {
            var selectList = this.selectList;

            var txtTracks = [];
            var imgTracks = [];
            // 遍历选中列表
            selectList.forEach((item) => {
                let itemTracks = item.timeLine.VideoTracks;
                var previewId = item.id;

                // 遍历选中组合的轨道
                itemTracks.forEach((list) => {
                    // 判断是否文字素材或者图片素材
                    if (list.Type === "Subtitle" || list.Type === "Image") {
                        // 遍历轨道里的素材
                        list.VideoTrackClips.forEach((val) => {
                            val.checked = val.checked ? true : false;
                            val.previewId = previewId;
                            var tracks = [];
                            switch (list.Type) {
                                case "Subtitle":
                                    tracks = txtTracks;
                                    break;
                                case "Image":
                                    tracks = imgTracks;
                                    break;
                                default:
                                    tracks = [];
                                    break;
                            }
                            let checkIn = tracks.find(
                                (res) => res.Id === val.Id
                            );
                            !checkIn && tracks.push(val);
                        });
                    }
                });
            });
            let edits = {
                txts: txtTracks,
                images: imgTracks,
                filters: [],
            };
            this.edits = JSON.stringify(edits);
        },
        //字幕修改
        textEdit(info) {
            let textClip = JSON.parse(JSON.stringify(info));
            // console.log(textClip);
            // textClip.Alignment = "Center";
            textClip.Y = textClip.Y > 1 ? textClip.Y / 100 : textClip.Y;
            var selectList = this.selectList;
            selectList.forEach((item, index) => {
                let tracks = item.timeLine.VideoTracks;
                let txtIndex = tracks.findIndex(
                    (clip) => clip.clip_type === "txt"
                );
                if (txtIndex > -1) {
                    let clips = tracks[txtIndex].VideoTrackClips;

                    clips.map((list, i) => {
                        if (list.Id === textClip.Id) {
                            clips[i] = textClip;
                        }
                    });
                    console.log(item);
                    this.setUpState(item);
                }
            });
            this.setPreviewList();
        },
        playTimer(val) {
            this.playTime = val;
        },
        // 新 end
    },
};
</script>

<style lang="less" scoped>
.combination {
    height: 100%;
    margin: 0 -20px;
    display: flex;
    > .left,
    .center {
        flex: 1;
    }
    .left {
        min-width: 480px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }
    .center {
        padding: 0 20px;
        min-width: 400px;
    }
    .right {
        flex: 0 0 440px;
    }
}
.list {
    flex: 1;
    height: 0;
}
.page {
    height: 60px;
    display: flex;
    align-items: center;
}
</style>