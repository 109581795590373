var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area"},[_c('div',{staticClass:"play"},[_c('div',{ref:"play_box",staticClass:"play-box"},[_c('div',{staticClass:"edit_area",style:({
                    width: Math.ceil(_vm.paly_area.w) + 'px',
                    height: Math.ceil(_vm.paly_area.h) + 'px',
                })},[_c('div',{staticClass:"txt",staticStyle:{"position":"absolute","font-size":"20pt","font-family":"SimSun"}}),_c('div',{staticClass:"resize_box"},[_vm._l((_vm.imgClips),function(image){return _c('div',{key:image.Id,staticClass:"track_box",style:({
                            width: image.Width * 100 + '%',
                            height: image.Height * 100 + '%',
                            top: image.Y * 100 + '%',
                            left: image.X * 100 + '%',
                        }),on:{"click":function($event){return _vm.showResize(image)}}})}),_c('VueDragResize',{directives:[{name:"show",rawName:"v-show",value:(_vm.moveAdd.isShow),expression:"moveAdd.isShow"}],key:_vm.moveAdd.radom,staticStyle:{"z-index":"99"},attrs:{"isActive":true,"parentW":Math.ceil(_vm.paly_area.w),"parentH":Math.ceil(_vm.paly_area.h),"parent-limitation":true,"aspectRatio":true,"isResizable":true,"w":parseInt(_vm.moveAdd.width),"h":_vm.moveAdd.height,"x":_vm.moveAdd.x,"y":_vm.moveAdd.y},on:{"resizing":_vm.resize,"dragging":_vm.resize,"dragstop":_vm.dragstop,"resizestop":_vm.dragstop,"activated":_vm.hideResize,"deactivated":_vm.deactivated}},[_c('img',{attrs:{"src":_vm.selectTrack.Src,"alt":"","width":"100%"}})])],2)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"video_box",staticClass:"video",attrs:{"element-loading-text":"正在生成预览视频","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.8)"}},[_c('div',{style:({ height: _vm.paly_area.h + 'px' }),attrs:{"id":"player-wrapper"}})])]),_c('div',{staticClass:"play-control"},[_c('div',{staticClass:"schedule"},[_c('el-slider',{attrs:{"step":0.01,"max":_vm.duration},on:{"change":_vm.setPlayTime},model:{value:(_vm.currTime),callback:function ($$v) {_vm.currTime=$$v},expression:"currTime"}})],1),_c('div',{staticClass:"control_row"},[_c('div',{staticClass:"control_time lr"},[_vm._v(" "+_vm._s(_vm.currTime)+"s / "+_vm._s(_vm.duration)+"s ")]),_c('div',{staticClass:"play_btn"},[(!_vm.getPlayTimer)?_c('i',{staticClass:"iconfont icon-bofangjian",on:{"click":function($event){return _vm.play()}}}):_c('i',{staticClass:"iconfont icon-zanting",on:{"click":function($event){return _vm.pause()}}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }