<template>
    <div class="area">
        <div class="hd">
            <div class="bar">
                <div>
                    选择组合已选 <span>{{ select_count }}</span> 个
                </div>
                <div v-if="!isChecks">
                    <el-button type="primary" size="mini" @click="modeTab(1)"
                        >多选</el-button
                    >
                </div>
                <div v-else>
                    <el-button type="primary" size="mini">删除字幕</el-button>
                    <el-button
                        type="primary"
                        size="mini"
                        @click="all_select(true)"
                        v-if="select_count < 20"
                        >全选</el-button
                    >
                    <el-button
                        type="primary"
                        size="mini"
                        @click="all_select(false)"
                        v-else
                        >全不选</el-button
                    >
                    <el-button type="primary" size="mini" @click="modeTab(0)"
                        >取消</el-button
                    >
                </div>
            </div>
            <!-- <div class="screen">
                <div class="screen-item">
                    <el-select v-model="value" placeholder="请选择" size="mini">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="screen-item">
                    <el-select v-model="value" placeholder="请选择" size="mini">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="screen-item">
                    <el-select v-model="value" placeholder="请选择" size="mini">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="screen-item">
                    <el-select v-model="value" placeholder="请选择" size="mini">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div> -->
        </div>
        <div class="body" v-loading="isLoading">
            <div class="video-content">
                <div class="video-group">
                    <div
                        class="video-list"
                        v-for="(item, index) in video_list"
                        :key="index"
                        @click="changeVideo(item, index)"
                    >
                        <div
                            class="list-box"
                            :class="item.isCheck ? 'active' : ''"
                        >
                            <span
                                class="iconfont icon-keshangxian check-btn"
                            ></span>
                            <div class="img-box">
                                <div class="state">
                                    <el-tag
                                        type="danger"
                                        size="mini"
                                        v-show="item.renew"
                                        >未保存</el-tag
                                    >
                                    <el-tag
                                        type="success"
                                        size="mini"
                                        v-if="item.saved"
                                        >已合成</el-tag
                                    >
                                </div>
                                <div
                                    class="play-btn"
                                    :class="item.id === play_id ? 'active' : ''"
                                    @click.stop="previewVideo(item, index)"
                                >
                                    <span
                                        class="iconfont icon-bofangjian"
                                    ></span>
                                </div>
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.child[0].coverUrl"
                                    fit="contain"
                                ></el-image>
                                <div class="child-box">
                                    <div
                                        class="child-list"
                                        v-for="(k, i) in item.child"
                                        :key="i"
                                    >
                                        <el-image
                                            style="width: 100%; height: 100%"
                                            :src="pic + k.coverUrl"
                                            fit="cover"
                                        ></el-image>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="info">
                                <div class="">
                                    {{ item.id.slice(-5) }}
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import {
    getPreviewList,
    generateCaptions,
    getTimeLine,
    gitTimeLine,
} from "@/api/engi";
export default {
    name: "List",
    props: ["play_size", "list", "select_count", "play_id", "isLoading"],
    watch: {
        list(list) {
            // console.log("接收预览列表", list);
            this.video_list = list;
        },
        play_size(val) {
            // 设置时间线，预览框宽高
            let play_size = JSON.parse(this.play_size);
            this.select.forEach((list) => {
                list.timeLine.FECanvas = play_size;
                let upArr = [];
                let json = {
                    previewId: list.id,
                    timeLine: JSON.stringify(list.timeLine),
                };
                upArr.push(json);
                let updata = {
                    timeLineArrayString: JSON.stringify(upArr),
                };
                gitTimeLine(updata);
            });
        },
    },
    data() {
        return {
            value: 0,
            selected: "",
            options: [{ label: "暂不开放", value: 0 }],
            pic: this.$store.state.pic,
            video_list: [],
            total: 0,
            where: {
                projectId: this.$store.state.fiss_id,
                pageSize: 20,
                currentPage: 1,
                status: 0,
            },
            isChecks: 0, //0单选，1多选
            select: [], // 选中
            get_timeline_data: {
                projectId: this.$store.state.fiss_id,
                group: [],
            },
        };
    },
    mounted() {
        Bus.$on("addTxt", (res) => {
            // console.log(res);
            this.select.forEach((item) => {
                // console.log(item.timeLine.VideoTracks);
                var index = item.timeLine.VideoTracks.findIndex(
                    (clip) => clip.clip_type == "txt"
                );
                if (index == -1) {
                    // 没有手动添加过字幕
                    let clip_txt = {
                        Id: Math.floor(Math.random() * 1000000),
                        clip_type: "txt",
                        VideoTrackClips: [],
                        Type: "Subtitle",
                    };
                    let num = item.timeLine.VideoTracks.push(clip_txt);
                    // console.log(num);
                    index = num - 1;
                }
                // console.log(item.timeLine.VideoTracks, index);
                item.timeLine.VideoTracks[index].VideoTrackClips.push(res);

                // 给父组件推送选择列表
                this.$emit("changeList", this.select);
                // this.$emit("changeList", this.select);

                // gitTimeLine({ timeLineArrayString: JSON.stringify(this.select) });
            });
        });
        Bus.$on("saveFilter", (val) => {
            let saveLineData = [];
            // 更新滤镜
            this.select.forEach((item) => {
                let filterIndex = item.timeLine.VideoTracks.findIndex(
                    (list) => list.Type == "Effect"
                );
                if (filterIndex === -1) {
                    // 不存在滤镜
                    let trackClip = {
                        Id: Math.ceil(Math.random() * 10000000),
                        Type: "Effect",
                        Visible: true,
                        Count: 1,
                        VideoTrackClips: [
                            {
                                Type: "Filter",
                                Id: Math.ceil(Math.random() * 10000000),
                                TrackId: 3,
                                Index: 138,
                                SubType: "pfc", //滤镜类型
                                Name: "filters.pfc",
                                CoverUrl:
                                    "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg",
                                Properties: {},
                                Category: "film",
                                EffectType: "filter",
                                EffectConfig: {
                                    Image: "https://ice-pub-media.myalicdn.com/websdk/lut/32/PFC.png",
                                },
                                TimelineIn: 0,
                                TimelineOut: 100,
                                Duration: 100,
                                X: 0,
                                Y: 0,
                                Width: 0,
                                Height: 0,
                                CanScale: false,
                                ExtParams: "effect=pfc",
                            },
                        ],
                    };
                    trackClip.VideoTrackClips[0].SubType = val;
                    trackClip.VideoTrackClips[0].Name = `filters.${val}`;
                    trackClip.VideoTrackClips[0].ExtParams = `effect=${val}`;
                    console.log(item.timeLine.VideoTracks);
                    item.timeLine.VideoTracks.push(trackClip);
                } else {
                    // 存在滤镜
                    item.timeLine.VideoTracks[
                        filterIndex
                    ].VideoTrackClips[0].SubType = val;
                    item.timeLine.VideoTracks[
                        filterIndex
                    ].VideoTrackClips[0].Name = `filters.${val}`;
                    item.timeLine.VideoTracks[
                        filterIndex
                    ].VideoTrackClips[0].ExtParams = `effect=${val}`;
                }
                let json = {
                    previewId: item.id,
                    timeLine: item.timeLine,
                };
                saveLineData.push(json);
            });
            // console.log(saveLineData);
            gitTimeLine({ timeLineArrayString: JSON.stringify(saveLineData) });
        });
        Bus.$on("upLine", (res) => {
            console.log(res);
            let upArr = [];
            let index = this.video_list.findIndex((list) => list.id == res.id);
            this.video_list[index].timeLine = res.timeLine;

            let json = {
                previewId: this.video_list[index].id,
                timeLine: JSON.stringify(res.timeLine),
            };
            upArr.push(json);
            let updata = {
                timeLineArrayString: JSON.stringify(upArr),
            };
            gitTimeLine(updata);
        });
        Bus.$on("upImg", (res) => {
            let obj = this.select[this.select.length - 1];
            let timeline = JSON.parse(obj.timeLine);
        });
        // this.getVideoList();
    },
    methods: {
        // 选择操作
        async changeVideo(info, index) {
            let list = {
                index,
                info,
            };
            // console.log(list);
            this.$emit("changeList", list);
        },
        // 加载预览
        previewVideo(info, index) {
            let list = {
                index,
                info,
            };
            this.$emit("payPreview", list);
            // if (!videos.timeLine) return;
            // let index = this.select.findIndex((res) => res.id == videos.id);
            // if (index > -1) {
            //     this.select.splice(index, 1);
            // }
            // this.select.push(videos);
            // this.$emit("changeList", this.select);
        },

        async getCaotions() {
            let ids = [];
            let timeline_indes = [];
            this.select.forEach((item) => {
                if (!item.timeLine) {
                    timeline_indes.push(item.id);
                }
                ids.push(item.id);
            });

            // // 选中识别字幕的存在没有timeLine的，先处理timeLine
            if (timeline_indes.length) {
                this.get_timeline_data.previewIdList =
                    timeline_indes.toString();
                // 获得时间线
                await getTimeLine(this.get_timeline_data);
            }

            let captions_data = {
                previewIdList: ids.toString(),
            };
            // 识别字幕，更新时间线
            let captions = await generateCaptions(captions_data);
            // captions.data.forEach((item) => {
            //     item.timeLine = JSON.parse(item.timeLine);
            //     let captionsTrack = [];
            //     item.timeLine.VideoTracks.forEach((list) => {
            //         if (list.Type == "Subtitle") {
            //             list.VideoTrackClips.forEach(caption_list => {
            //                 if (caption_list._y) {
            //                     delete caption_list._y;
            //                     caption_list.y = 0.9;
            //                 }
            //             })
            //         } else {
            //             console.log(list.Type);
            //         }
            //     });
            // });
            // console.log(captions);
            captions.data.forEach((item) => {
                let index = this.video_list.findIndex(
                    (list) => list.id === item.videoPreviewId
                );
                this.$set(
                    this.video_list[index],
                    "timeLine",
                    JSON.parse(item.timeLine)
                );
            });
            this.$emit("changeList", this.select);
        },
        // 添加图片
        addImg(img) {
            let url = img.url;
            // 图片轨道
            let list_id =
                Date.parse(new Date()) + "_" + Math.floor(Math.random() * 100);
            let img_list = {
                Type: "Image",
                Id: list_id,
                TrackId: 1,
                // MediaId: "66864e82a7079a2d9625b9f618ecdc18",
                Src: url,
                TimelineIn: img.timeIn,
                TimelineOut: img.timeout,
                Duration: 15,
                X: 0.5,
                Y: 0.5,
                Width: 0.185,
                Height: 0.104,
                BusinessType: "Sticker",
            };
            let savaData = [];
            this.select.forEach((item) => {
                let imgIndex = item.timeLine.VideoTracks.findIndex(
                    (list) => list.Type === "Image"
                );
                if (imgIndex === -1) {
                    let trackId =
                        Date.parse(new Date()) +
                        "_" +
                        Math.floor(Math.random() * 1000);
                    let trackClip = {
                        Id: trackId,
                        Type: "Image",
                        Count: 1,
                        VideoTrackClips: [],
                    };
                    let img_clip = img_list;
                    trackClip.VideoTrackClips.push(img_clip);
                    // 添加img轨道
                    item.timeLine.VideoTracks.push(trackClip);
                } else {
                    // 在img轨道添加图片
                    let img_clip = img_list;
                    console.log(item.timeLine.VideoTracks);
                    console.log(imgIndex);
                    item.timeLine.VideoTracks[imgIndex].VideoTrackClips.push(
                        img_clip
                    );
                }
                let savaList = { previewId: item.id, timeLine: item.timeLine };
                savaData.push(savaList);
            });

            this.$emit("changeList", this.select);

            gitTimeLine({ timeLineArrayString: JSON.stringify(savaData) });
        },
        upImg(img, type) {
            // console.log(img);
            // console.log(type);
            let imgId = img.Id;
            let savaData = [];
            this.video_list.forEach((item) => {
                if (item.timeLine) {
                    // 拿到图片所在索引
                    let imgIndex = item.timeLine.VideoTracks.findIndex(
                        (list) => list.Type === "Image"
                    );
                    if (imgIndex > -1) {
                        let imgListIndex = item.timeLine.VideoTracks[
                            imgIndex
                        ].VideoTrackClips.findIndex(
                            (checkImg) => checkImg.Id === imgId
                        );
                        if (imgListIndex > -1) {
                            if (type == "del") {
                                // 删除的图片
                                item.timeLine.VideoTracks[
                                    imgIndex
                                ].VideoTrackClips.splice(imgListIndex, 1);
                            } else if (type == "edit") {
                                item.timeLine.VideoTracks[
                                    imgIndex
                                ].VideoTrackClips[imgListIndex].X =
                                    img.x || img.X;
                                item.timeLine.VideoTracks[
                                    imgIndex
                                ].VideoTrackClips[imgListIndex].Y =
                                    img.y || img.Y;
                                item.timeLine.VideoTracks[
                                    imgIndex
                                ].VideoTrackClips[imgListIndex].Width =
                                    img.width || img.Width;
                                item.timeLine.VideoTracks[
                                    imgIndex
                                ].VideoTrackClips[imgListIndex].Height =
                                    img.height || img.Height;

                                if (img.TimelineIn) {
                                    item.timeLine.VideoTracks[
                                        imgIndex
                                    ].VideoTrackClips[imgListIndex].TimelineIn =
                                        img.TimelineIn;
                                }
                                if (img.TimelineOut) {
                                    item.timeLine.VideoTracks[
                                        imgIndex
                                    ].VideoTrackClips[
                                        imgListIndex
                                    ].TimelineOut = img.TimelineOut;
                                }
                            }

                            let json = {
                                previewId: item.id,
                                timeLine: item.timeLine,
                            };
                            savaData.push(json);
                        }
                    }
                }
            });
            gitTimeLine({ timeLineArrayString: JSON.stringify(savaData) });
            // 给父组件推送选择列表
            this.$emit("changeList", this.select);
        },

        setXY(index) {
            let timeLine = this.video_list[index];
            console.log(timeLine);
        },
        modeTab(isChecks) {
            // 切换选择模式，isChecks 0_单选,1_多选
            this.isChecks = isChecks;
            if (isChecks == 0) {
                // console.log(this.$parent.selectList[0]);
                // let change = JSON.stringify()
                // this.$parent.selectList = [];
            }
            // this.select = [];
        },
        // 全选全不选
        all_select(val) {
            this.$emit("change_all", val);
        },
        async changePreview(info, index) {
            if (info.id === this.selected.id) return;
            let timeLine = null;
            if (!info.timeLine) {
                let timeLinReq = {
                    projectId: this.$store.state.fiss_id,
                    previewIdList: info.id,
                };
                const loading = this.$loading({
                    lock: true,
                    text: "正在生成预览数据",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let line = await getTimeLine(timeLinReq);
                loading.close();
                if (line.code != 200) {
                    this.$message.error("生成预览视频失败");
                    return;
                } else {
                    timeLine = line.data[0].timeLine;
                    this.video_list[index].timeLine = timeLine;
                }
            } else {
                timeLine = info.timeLine;
            }
            // console.log(JSON.parse(timeLine));
            if (this.selected) {
                let oldTimeline = this.$store.state.timeLine;
                // console.log("保存时间线：", oldTimeline);

                this.gitTimeLine(oldTimeline);
            }

            let selected = {
                id: info.id,
                index,
            };
            this.selected = selected;
            // this.$store.commit("SET_TIMELINE", timeLine);
        },
        async gitTimeLine(line) {
            let selected = this.selected;

            let json = {
                previewId: selected.id,
                timeLine: JSON.stringify(line),
            };
            let res = await gitTimeLine(json);
            if (res.code == 200) {
                this.video_list[selected.index].timeLine = line;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.area {
    display: flex;
    flex-direction: column;
}
.bar {
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
.screen {
    height: 36px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.screen-item + .screen-item {
    margin-left: 12px;
}
.body {
    flex: 1;
    height: 0;
    overflow: auto;
}
.video-group {
    display: flex;
    flex-wrap: wrap;
    padding: 0 6px;
}
.video-list {
    width: 25%;
    box-sizing: border-box;
    padding: 6px;
    &:hover {
        .play-btn {
            display: block;
        }
    }
    .list-box {
        position: relative;
        border: 2px solid transparent;
        border-radius: 8px;
        overflow: hidden;
        &:hover {
            box-shadow: 0px 0px 12px #a5a1a1;
            transform: scale(1.03);
        }
        &.active {
            border-color: #349ffd;
            .check-btn {
                color: #349ffd;
            }
        }
        .check-btn {
            position: absolute;
            left: 6px;
            top: 6px;
            z-index: 99;
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.play-btn {
    display: none;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    cursor: pointer;
    &.active {
        display: block !important;
    }
    .iconfont {
        font-size: 13px;
        color: #e8ecef;
    }
}
.img-box {
    // height: 145px;
    background: url(../../../assets/images/ma.png);
    background-size: 8px;
    position: relative;
    line-height: 0;
}
.state {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    padding: 6px;
}
.child-box {
    width: 100%;
    height: 36px;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    .child-list {
        padding: 2px 0;
        width: 14px;
        background-color: rgba(255, 255, 255, 0.6);
        margin: 0 2px;
    }
}
.info {
    font-size: 12px;
    padding: 4px 6px;
}
.foo {
    height: 46px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
}
</style>